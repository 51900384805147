import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHooksHelpers } from "."
import { Actions, Context as DAContext, IDatosAbiertosContextType, StateElems } from "../context/DatosAbiertosContext"
import { downloadCSV } from "../helpers/helperFunctions"
import { IHookHelpersHook, ISearchParams } from "./useHooksHelpers"

type TDispatchArrayStrings<T> = Dispatch<SetStateAction<T>>

export interface IOpendataHistoryHook
  extends Partial<StateElems>,
    Partial<Actions>,
    Partial<IHookHelpersHook> {
  // ******** Variables
  loadingVariable: boolean
  isSearchAvailable: boolean
  ordenParam: boolean
  categoriaIndex: number
  sectorIndex: number

  // ********** Getters
  fetchOpendataHistory: () => Promise<void>

  // ********** Setters
  setDescripcionesBuscadas: TDispatchArrayStrings<string[]>
  setPeriodosBuscados: TDispatchArrayStrings<string[]>
  setCategoriaIndex: TDispatchArrayStrings<number>
  setSectorIndex: TDispatchArrayStrings<number>
  setOrdenarPorParam: TDispatchArrayStrings<string>

  // ******* Methods
  handleSortByColumnIndex: (i: number) => void
  resetFilters: () => void
  exportSearch: () => Promise<void>
}

export default (): IOpendataHistoryHook => {
  const [isSearchAvailable, setSearchAvailable] = useState(false)
  const [loadingVariable, setLoadingVariable] = useState(false)

  const [periodosBuscados, setPeriodosBuscados] = useState<string[]>(null)
  const [categoriaIndex, setCategoriaIndex] = useState(null)
  const [sectorIndex, setSectorIndex] = useState(null)
  const [variablesBuscadas, setVariablesBuscadas] = useState<string[]>(null)
  const [descripcionesBuscadas, setDescripcionesBuscadas] = useState<string[]>(
    null
  )

  const [ordenarPorParam, setOrdenarPorParam] = useState("_id")
  const [ordenParam, setOrdenParam] = useState<boolean>(null)

  const {
    state: { datosAbiertos, categorias, sectores, historyVariables, periodos, ...restOfContextState },
    getOpendataHistory,
    getQueryVariable,
    getAllVariables,
    getDescripciones,
    getOpendataHistoryPeriodos,
    resetDatosAbiertos,
    resetVariables,
    ...restOfContextMethods
  } = useContext<IDatosAbiertosContextType>(DAContext)

  const [searchParamsInmutable, setSearchParamsInmutable] = useState([])

  useEffect(() => {
    let updated = []

    if (variablesBuscadas) {
      updated = [
        { id: 0, field: "Periodo", colProp: "_id" },
        ...(descripcionesBuscadas
          ? descripcionesBuscadas.map((descripcionBuscada, i) => ({
            id: i + 1,
            field: descripcionBuscada,
            colProp: variablesBuscadas[i]
          }))
          : [])
      ]
    }

    if (searchParamsInmutable.length === 0 && updated.length > 0) {
      setSearchParamsInmutable(updated)
    }

  }, [descripcionesBuscadas, variablesBuscadas, searchParamsInmutable])

  const { listTitles, colProps, chips } = useHooksHelpers({
    searchParamsList: searchParamsInmutable
  })

  const categoriaSeleccionada = useMemo(() => {
    if (categorias) {
      return categorias[categoriaIndex]
    }
    return null
  }, [categoriaIndex, categorias])

  const sectorSeleccionado = useMemo(() => {
    if (sectores) {
      return sectores[sectorIndex]
    }
    return null
  }, [sectorIndex, sectores])


  const fetchOpendataHistory = useCallback(async () => {
    if (isSearchAvailable && categoriaSeleccionada && sectorSeleccionado) {
      const variablesParam = descripcionesBuscadas?.map(
        async descripcionBuscada => {
          return await getQueryVariable(
            {
              categoria: categoriaSeleccionada,
              sector: sectorSeleccionado,
              descripcion: descripcionBuscada
            },
            "historico"
          )
        }
      )
      const variables = await Promise.all(variablesParam)
      setVariablesBuscadas(variables)

      setSearchParamsInmutable([])

      await getOpendataHistory({
        variables,
        periodos: periodosBuscados,
        ordenarPor: ordenarPorParam,
        orden: ordenParam ? -1 : 1 // 1 ASC -1 DESC
      })
    }
  }, [
    isSearchAvailable,
    descripcionesBuscadas,
    ordenarPorParam,
    ordenParam,
    categoriaSeleccionada,
    sectorSeleccionado,
    periodosBuscados
  ])

  const handleSortByColumnIndex = useCallback(
    i => {
      let searchParam: ISearchParams

      if (i === 0) {
        searchParam = searchParamsInmutable[0]
      } else {
        searchParam = searchParamsInmutable.find(e => e.id === i)
      }

      setOrdenParam(prev => !prev)
      setOrdenarPorParam(searchParam.colProp)
    },
    [searchParamsInmutable]
  )

  const resetFilters = useCallback(() => {
    setCategoriaIndex(null)
    setSectorIndex(null)
    setOrdenarPorParam("_id")
    setOrdenParam(null)
    setDescripcionesBuscadas(null)
    setPeriodosBuscados(null)
    resetDatosAbiertos()
    resetVariables()
  }, [])

  useEffect(() => {
    if (!historyVariables) {
      setLoadingVariable(true)
      getAllVariables("historico")
    } else {
      setLoadingVariable(false)
    }
  }, [historyVariables])

  useEffect(() => {
    if (isSearchAvailable) {
      fetchOpendataHistory()
    }
  }, [ordenParam])

  useEffect(() => {
    if (categoriaSeleccionada && sectorSeleccionado) {
      getDescripciones(categoriaSeleccionada, sectorSeleccionado, "historico")
    }
  }, [categoriaSeleccionada, sectorSeleccionado])

  useEffect(() => {
    if (!periodos) {
      getOpendataHistoryPeriodos()
    }
  }, [periodos])

  useEffect(() => {
    Boolean(
      categoriaIndex !== null &&
      sectorIndex !== null &&
      descripcionesBuscadas?.length > 0 &&
      periodosBuscados?.length > 0
    )
      ? setSearchAvailable(true)
      : setSearchAvailable(false)
  }, [categoriaIndex, sectorIndex, descripcionesBuscadas, periodosBuscados])

  useEffect(() => {
    resetFilters()
    return () => resetFilters()
  }, [])

  const exportSearch = async () => {
    await downloadCSV(datosAbiertos, "consulta-historicos")
  }


  return {
    // Variables
    loadingVariable,
    isSearchAvailable,
    ordenParam,
    categoriaIndex,
    sectorIndex,

    // Getters
    fetchOpendataHistory,

    // Setters
    setDescripcionesBuscadas,
    setPeriodosBuscados,
    setCategoriaIndex,
    setSectorIndex,
    setOrdenarPorParam,

    // Helpers
    resetFilters,
    exportSearch,

    // Data table
    listTitles,
    colProps,
    chips,
    handleSortByColumnIndex,

    // Context state
    datosAbiertos,
    categorias,
    sectores,
    periodos,
    ...restOfContextState,

    // Context methods
    resetDatosAbiertos,
    ...restOfContextMethods
  }
}
