// noinspection JSSuspiciousNameCombination

import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

const CARD_MIN_WIDTH: number = 250

interface IStyleProps {
  collapsibleInformationHeight: number
  summaryVisible: boolean
}

export default makeStyles((theme: ITheme) => ({
  cardRoot: {
    width: "100%",
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(0.5),
    background: "none",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
      minWidth: CARD_MIN_WIDTH,
      width: CARD_MIN_WIDTH,
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
      cursor: 'pointer',
    },
  },

  cardContent: {
    padding: theme.spacing(2, 0, 0, 0),
    textAlign: "center",
  },

  cardTitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  imgContainer: {},

  cardInfoWrapper: {
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },

  cardInfo: {
    overflow: "hidden",
    "& p": {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.text.disabled,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },

  textRight: {
    textAlign: "right",
  },

  textLeft: {
    textAlign: "left",
  },

  image: {
    border: `1px solid ${theme.palette.overlays.overlay}`,
    width: "100%",
    height: `${
      typeof window !== "undefined" ? window.innerWidth - 64 - 16 : 0
    }px`,
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[0],
    [theme.breakpoints.up("sm")]: {
      height: CARD_MIN_WIDTH,
    },
  },
  textContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,

    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    padding: "5%",
    height: ({ collapsibleInformationHeight }: IStyleProps) =>
      `${collapsibleInformationHeight}%`,

    borderBottomRightRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderRadius: theme.roundness,

    transition: theme.palette.extras.transitions.fastEaseInOut,
    background: theme.palette.secondary.main,
    "& span": {
      color: theme.palette.text.lightText,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },

    "& p": {
      color: theme.palette.text.lightText,
      fontSize: theme.typography.body2.fontSize,
    },
  },

  title: {
    textAlign: 'center',
    width: '100%'
  }
}))
