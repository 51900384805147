import * as React from "react"
import { ReactNode } from "react"
import { FaEnvelope, FaFacebook, FaPhone } from "react-icons/fa"

interface IFooterNavigationLinks {
  text: string
  items: ILink[]
}

export interface ILink {
  path: string
  text: string
  external?: boolean
}

interface ISocialLink {
  title: string
  icon: ReactNode
  url: string
}

const footerLinks: ILink[] = [
  {
    path: "/preguntas-frecuentes",
    text: "Preguntas frecuentes",
    external: false
  },
  {
    path: "/glosario",
    text: "Glosario",
    external: false
  },
  {
    path: "/contacto",
    text: "Contacto",
    external: false
  },

  {
    path: "/legal",
    text: "Aviso de privacidad",
    external: false
  }
]

const footerNavigationLinks: IFooterNavigationLinks[] = [
  {
    text: "Inicio",
    items: [
      {
        path: "/",
        text: "Inicio"
      }
    ]
  },
  {
    text: "PNIF",
    items: [
      {
        path: "/pnif",
        text: "Introducción a la PNIF"
      },
      {
        path: "/pnif",
        text: "Objetivos"
      },
      {
        path: "/pnif",
        text: "Diagnóstico de la inclusión financiera"
      },
      {
        path: "/pnif",
        text: "Construcción de la PNIF"
      },
      {
        path: "/pnif",
        text: "Aliados"
      },
      {
        path: "/pnif",
        text: "Mapeo de riesgos"
      }
    ]
  },

  {
    text: "Seguimiento de indicadores",
    items: [
      {
        path: "/seguimiento-de-indicadores",
        text: "Seguimiento de indicadores"
      }
    ]
  },

  {
    text: "Datos",
    items: [
      {
        path: "/datos",
        text: "Datos"
      }
    ]
  },

  {
    text: "Mapas",
    items: [
      {
        path: "/mapas",
        text: "Mapas"
      }
    ]
  },

  {
    text: "Documentos",
    items: [
      {
        path: "/documentos",
        text: "Documentos"
      }
    ]
  },

  {
    text: "Comunidad",
    items: [
      {
        path: "/ligas-de-interes",
        text: "Ligas de interés de inclusión financiera"
      }
    ]
  },

  {
    text: "Acerca de",
    items: [
      {
        path: "/acerca",
        text: "Esta plataforma"
      },
      {
        path: "/acerca",
        text: "La Inclusión Financiera"
      },
      {
        path: "/acerca",
        text: "Consejo Nacional de Inclusión Financiera"
      },
      {
        path: "/acerca",
        text: "Comité de Educación Financiera"
      }
    ]
  }
]

const navbarLinks: ILink[] = [
  {
    path: "/",
    text: "Inicio"
  },
  {
    path: "/pnif",
    text: "PNIF"
  },
  {
    path: "/seguimiento-de-indicadores",
    text: "Seguimiento de indicadores"
  },
  {
    path: "/datos",
    text: "Datos"
  },
  {
    path: "/mapas",
    text: "Mapas"
  },
  {
    path: "/documentos",
    text: "Documentos"
  },
  {
    path: "/acerca",
    text: "Acerca de"
  }
]

const socialLinks: ISocialLink[] = [
]

export { footerLinks, socialLinks, navbarLinks, footerNavigationLinks }
