import { Grid, Tooltip, Typography } from "@material-ui/core"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react"
import { RiFilterOffLine } from "react-icons/ri"
import { Accordion } from "../../_common"
import Header from "../../OpenData/DataControls/Header"
import { IDataCategory } from "./PageNavigator"

import useStyles from "./styles"

interface INavigator<T> {
  title: string
  titleStrong: string
  data: IDataCategory<T>[]
  loading: boolean
  loadingText: string
  currentSelection: T
  setCurrentSelection: Dispatch<SetStateAction<T>>
  setCurrentCategory: Dispatch<SetStateAction<IDataCategory<T>>>
  mobileAction: (() => void) | (() => Promise<void>)
  instructions: string
  scrollOffset?: number
  viewPortHeight?: boolean
  footer?: ReactNode
}

const Navigator = <T extends { id: string }>({
                                               data,
                                               currentSelection,
                                               setCurrentSelection,
                                               setCurrentCategory,
                                               scrollOffset = 0,
                                               mobileAction,
                                               viewPortHeight,
                                               footer,
                                               instructions
                                             }: INavigator<T>) => {
  const styles = useStyles({ viewPortHeight })

  const breakpoint = useBreakpoint()

  const accordionData = useMemo(
    () =>
      data.map((e, i) => ({
        id: e.id,
        title: e.title,
        data: e.data,
        expanded: i === 0
      })),
    [data]
  )

  const [accordions, setAccordions] = useState([])

  useEffect(() => {
    if (accordionData && accordions.length === 0) {
      setAccordions(accordionData)
    }
  }, [accordionData])

  const handleToggleAccordion = (index: number) => {
    const updatedAccordions = [...accordions]
    const currentAccordion = updatedAccordions[index]

    updatedAccordions.map((accordion) => {
      if (accordion.id === currentAccordion.id) {
        return accordion.expanded = !accordion.expanded
      } else {
        return accordion.expanded = false
      }
    })
    setAccordions(updatedAccordions)
  }

  return (
    <>
      <Grid container item xs className={ styles.itemsStyle }>
        <ul className="category-list">
          <Typography className={ styles.helpText }>
            { instructions }
          </Typography>
          { accordions.map((e, i: number) => (
            <li key={ i }>
              <Accordion
                expanded={ e.expanded }
                onChange={ () => handleToggleAccordion(i) }
                title={ e.title }
                content={
                  <Grid item container className={ styles.itemCategory }>
                    <ul className="item-list">
                      { e.data.map((j, i: number) => {
                        return (
                          <li key={ i }>
                            <Grid
                              item
                              className={ styles.item }
                              onClick={ () => {
                                if (!breakpoint.sm) {
                                  typeof window !== "undefined" &&
                                  window.scrollTo({
                                    top: scrollOffset,
                                    behavior: "smooth"
                                  })
                                  setCurrentSelection(j)
                                  setCurrentCategory(e)
                                } else {
                                  mobileAction()
                                  setCurrentSelection(j)
                                  setCurrentCategory(e)
                                }
                              } }
                            >
                              <Tooltip
                                title={ j.name || j.descripcion || j.title }
                              >
                                <Typography
                                  component="span"
                                  id={
                                    j.id === currentSelection?.id
                                      ? "map-nav-active"
                                      : ""
                                  }
                                >
                                  { j.title }
                                </Typography>
                              </Tooltip>
                            </Grid>
                          </li>
                        )
                      }) }
                    </ul>
                  </Grid>
                }
              />
            </li>
          )) }
          { footer }
        </ul>
      </Grid>
    </>
  )
}

export default Navigator
