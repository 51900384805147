import { Box, MenuItem, Select } from "@material-ui/core"
import * as React from "react"
import { FC, Fragment } from "react"

import { FormItemLayout } from ".."

import { TAnyCallback } from "../../../helpers/Types"

import Input from "./styles"

interface IFormSelectValues {
  id: number | string
  text: string
}

interface FormSelectProps {
  values: IFormSelectValues[]
  onChange: TAnyCallback
  value: string
  name: string
  label?: string
  required?: boolean
  helper?: string
}

const FormSelect: FC<FormSelectProps> = ({
                                           values,
                                           label,
                                           required,
                                           helper,
                                           onChange,
                                           value,
                                           name
                                         }) => {
  return (
    <Fragment>
      <FormItemLayout { ...{ label, required, helper, name } }>
        <Box display="flex" flexDirection="column" width="100%">
          <Select
            displayEmpty
            value={ value }
            name={ name }
            onChange={ onChange }
            input={ <Input /> }
          >
            { values.map((e, i) => (
              <MenuItem key={ i } value={ e.text }>
                { e.text }
              </MenuItem>
            )) }
          </Select>
        </Box>
      </FormItemLayout>
    </Fragment>
  )
}

export default FormSelect
