import * as React from "react"
import { FC, useContext } from "react"

import { Modal } from "../components/_common"
import { Context as ModalContext, IModalContext } from "../context/ModalContext"

const ModalController: FC = () => {
  const {
    state: { hidden, component, title },
    closeModal
  } = useContext<IModalContext>(ModalContext)

  return (
    <Modal title={ title } open={ !hidden } onClose={ closeModal }>
      { component }
    </Modal>
  )
}

export default ModalController
