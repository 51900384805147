import { Grid } from "@material-ui/core"
import Img, { FluidObject } from "gatsby-image"
import * as React from "react"

import { SectionContainer, SectionTitle } from ".."


import useStyles from "./styles"

interface IIntro {
  title: string
  sectionID: string
  image: FluidObject
  alt: string
}

const ImageSection: React.FC<IIntro> = ({ image, title, alt, sectionID }) => {
  const styles = useStyles()


  return (
    <SectionContainer { ...{ sectionID } }>
      <Grid container>
        <SectionTitle { ...{ title } } />
        <Grid item container className={ styles.imgRoot }>
          <Grid item container className={ styles.imgContainer }>
            <Img className={ styles.img } fluid={ image } { ...{ alt } } />
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default ImageSection
