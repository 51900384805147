import { Box, IconButton, List, ListItem, ListSubheader, Typography } from "@material-ui/core"
import { Divider } from "@material-ui/core/"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as React from "react"
import { SectionContainer } from ".."

import { footerLinks, footerNavigationLinks, socialLinks } from "../../../data/navlinks"
import useStyles from "./styles"

const Footer: React.FC = () => {
  const styles = useStyles()

  const internalLinks = footerLinks.filter(e => !e.external)
  const externalLinks = footerLinks.filter(e => e.external)

  return (
    <SectionContainer sectionID="footer" dark>
      <Box className={ styles.footer }>
        { /*Lista de secciones*/ }
        <div className={ styles.listOfSections }>
          { footerNavigationLinks.map(({ text, items }, i) => (
            /* Item del footer (sección) */
            <List component="div" key={ i } className={ styles.section }>
              <ListSubheader component="div" className={ styles.subheader }>
                <Typography variant="body2" className={ styles.sectionTitle }>
                  { /*Título de la sección*/ }
                  { text }
                </Typography>
                <Divider classes={ { root:  styles.sectionTitleDivider  } } />
              </ListSubheader>
              <ul>
                { items.map(({ text, path }, j) => (
                  /* Lista de items dentro de la sección */
                  <ListItem key={ j } className={ styles.sectionItem }>
                    { path ? (
                      <AniLink
                        fade
                        to={ path }
                        className={ styles.sectionItemLink }
                      >
                        { /*Item dentro de la sección*/ }
                        { text }
                      </AniLink>
                    ) : (
                      <span className={ styles.sectionItemLink }>
                        { /*Item dentro de la sección*/ }
                        { text }
                      </span>
                    ) }
                  </ListItem>
                )) }
              </ul>
            </List>
          )) }
        </div>
        <div className={ styles.links }>
          { externalLinks.map((item, index) => {
            return (
              <a
                key={ index }
                href={ item.path }
                target="_blank"
                rel="noopener noreferrer"
              >
                { item.text }
              </a>
            )
          }) }
          { internalLinks.map((item, index) => {
            return (
              <AniLink key={ index } fade to={ item.path }>
                { item.text }
              </AniLink>
            )
          }) }
        </div>
        <div className={ styles.icons }>
          { socialLinks.map((item, index) => {
            return (
              <IconButton
                key={ index }
                aria-label={ item.title }
                onClick={ () =>
                  typeof window !== "undefined" && window.open(item.url)
                }
              >
                { item.icon }
              </IconButton>
            )
          }) }
        </div>
        <div className={ styles.copyright }>
          Política Nacional de Inclusión Financiera { new Date().getFullYear() }
        </div>

        <Box my={ 1 }>
          <Typography variant="caption" className={ styles.developedBy }>
            Consultoría técnica y desarrollo por Henderson & Alberro.
          </Typography>
        </Box>
      </Box>
    </SectionContainer>
  )
}

export default Footer
