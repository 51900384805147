import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { HEIGHT, MAX_WIDTH } from "../Layout/Layout"

interface IStyleProps {
  fullHeight: boolean
  blured: boolean
  dark: boolean
}

export default makeStyles((theme: ITheme) => ({
  section: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    backgroundColor: ({ dark }: IStyleProps) =>
      dark
        ? theme.palette.background.darkBackground
        : theme.palette.background.paper,
  },

  sectionContainer: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: ({ fullHeight }: IStyleProps) =>
      fullHeight && "calc(100vh - 64px)",
    padding: theme.spacing(4),
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      maxWidth: MAX_WIDTH,
    },
  },

  bg: {
    position: "absolute",
    left: 0,
    top: 0,
    filter: ({ blured }: IStyleProps) => (blured ? "blur(20px)" : "none"),
    height: "100%",
    width: "100%",
  },
}))
