import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  reversed?: boolean
}

export default makeStyles((theme: ITheme) => ({
  root: {
    flexDirection: ({ reversed }: IStyleProps) => reversed ? "row-reverse" : "row",
    [theme.breakpoints.up("md")]: {}
  }
}))
