import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  floating: {
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 888,
    margin: theme.spacing(0, 2, 2, 0),

    [theme.breakpoints.up("md")]: { margin: theme.spacing(0, 4, 4, 0) },
  },

  buttonContainer: {
    borderRadius: "50%",
  },
}))
