import { StateElems } from "."
import { ReducerAction } from "../contextHelpers"

export type ReducerType =
  | "set_datos_abiertos"
  | "set_search_params"
  | "set_variables"
  | "set_history_variables"
  | "set_descripciones"
  | "set_periodos"
  | "set_meta"
  | "set_loading"
  | "add_error"

export default (
  state: StateElems,
  action: ReducerAction<ReducerType, StateElems>
) => {
  const { type, payload } = action

  switch (type) {
    // MAIN
    case "set_datos_abiertos":
      return {
        ...state,
        datosAbiertos: payload.datosAbiertos,
        datosAbiertosLastFetch: payload.datosAbiertosLastFetch,
        pagina: payload.pagina,
        numPag: payload.numPag,
        numReg: payload.numReg,
        registrosPorPagina: payload.registrosPorPagina
      }
    case "set_meta":
      return {
        ...state,
        metadata: payload.metadata
      }
    case "set_search_params":
      return {
        ...state,
        datosAbiertosParams: payload.datosAbiertosParams
      }
    case "set_variables":
      return {
        ...state,
        variables: payload.variables,
        sectores: payload.sectores,
        categorias: payload.categorias
      }

    case "set_history_variables":
      return {
        ...state,
        historyVariables: payload.historyVariables,
        sectores: payload.sectores,
        categorias: payload.categorias
      }

    case "set_descripciones":
      return {
        ...state,
        descripciones: payload.descripciones
      }
    case "set_periodos":
      return {
        ...state,
        periodos: payload.periodos
      }

    // HELPERS
    case "set_loading":
      return { ...state, loadingDatosAbiertos: payload.loadingDatosAbiertos }
    case "add_error":
      return { ...state, errorMessage: payload.errorMessage }

    // DEFAULT
    default:
      return state
  }
}
