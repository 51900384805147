import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  regularBottom: boolean
  light: boolean
}

export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(2, 0),
    flexDirection: "column",
    justifyContent: "center",
  },
  description: {
    marginBottom: ({ regularBottom }: IStyleProps) =>
      !regularBottom && theme.spacing(4),
    lineHeight: theme.lineHeight(2),
    color: ({ light }: IStyleProps) => light && theme.palette.text.lightText,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.body1.fontSize,
    },
    [theme.breakpoints.up("md")]: {
    },
  },
}))
