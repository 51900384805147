import { Grid } from "@material-ui/core"
import Img, { FluidObject } from "gatsby-image"
import * as React from "react"
import useStyles from "./styles"

interface ISectionContainer {
  sectionID: string
  sectionBG?: FluidObject
  dark?: boolean
  // Works for 100% vw including navbar
  fullHeight?: boolean
  blured?: boolean
}

const SectionContainer: React.FC<ISectionContainer> = ({
                                                         children,
                                                         fullHeight,
                                                         sectionID,
                                                         sectionBG,
                                                         dark,
                                                         blured
                                                       }) => {
  const { sectionContainer, section, bg } = useStyles({
    fullHeight,
    blured,
    dark
  })

  return (
    <section id={ sectionID } className={ section }>
      { sectionBG && <Img className={ bg } fluid={ sectionBG } /> }
      <Grid className={ sectionContainer }>{ children }</Grid>
    </section>
  )
}

export default SectionContainer
