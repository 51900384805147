import { Grid } from "@material-ui/core"
import { ReactElement } from "react"
import * as React from "react"
import { SectionTitle } from "../index"

import useStyles from "./styles"

interface IPageHeaderProps {
  title: string
  icon: ReactElement
}

const PageHeader: React.FC<IPageHeaderProps> = ({ title, icon }) => {
  const styles = useStyles()

  return (
    <Grid item container className={ styles.introWrapper }>
      <Grid container item xs={ 12 } md={ 3 }>
        <Grid item xs container justify={ "center" } alignItems={ "center" } className={ styles.iconWrapper }>
          { icon }
        </Grid>
      </Grid>

      <Grid item xs={ 12 } md={ 9 } className={ styles.instructionsWrapper }>
        <SectionTitle { ...{ title } } />
      </Grid>
    </Grid>
  )
}

export default PageHeader
