import { Typography } from "@material-ui/core"
import * as React from "react"
import useStyles from "./styles"

interface IFormLabel {
  label: string
  name: string
  required?: boolean
}

const FormLabel: React.FC<IFormLabel> = ({ label, required, name }) => {
  const classes = useStyles()
  return (
    <label htmlFor={ name } className={ classes.label }>
      { label }
      { required && (
        <Typography
          variant="body2"
          component="strong"
          className={ classes.required }
        >
          *
        </Typography>
      ) }
    </label>
  )
}

export default FormLabel
