import { Button, CssBaseline } from "@material-ui/core/"

import { ThemeProvider } from "@material-ui/styles"
import * as React from "react"
import { ReactNode, useState } from "react"

import { AiFillWarning } from "react-icons/all"
import { Footer, Navbar } from ".."
import theme from "../../../App.theme"
import { Provider as DatosAbiertosContextProvider } from "../../../context/DatosAbiertosContext"
import { Provider as IndicadoresContextProvider } from "../../../context/IndicadoresContext"

import { Provider as ModalContextProvider } from "../../../context/ModalContext"



import ModalController from "../../../HOC/ModalController"
import { useBrowserDetection } from "../../../hooks"
import ENIFControls, { InitializeConfigurationParams } from "../../../lib/ENIFControls"
import { SEO } from "../../_common"

import useStyles from "./styles"

interface ILayoutProps {
  withNav: boolean
  seoTitle: string
  seoDescription: string
  children: ReactNode
}

if (typeof window !== "undefined") {
  require("smooth-scroll")("a[href*=\"#\"]")
}

// Initializes ENIF search controls
const initialState: InitializeConfigurationParams = {
  resultados: [],
  variables: null,
  secciones: null,
  preguntas: null,
  categorias: null,
  ordenarPor: "_id",
  orden: 1
}

ENIFControls.initialize(initialState)

export const HEIGHT: number = 64
export const MAX_WIDTH: number = 1440

const Layout: React.FC<ILayoutProps> = ({
                                          children,
                                          withNav,
                                          seoTitle = "",
                                          seoDescription = ""
                                        }) => {
  const styles = useStyles({ withNav, HEIGHT })

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop)
  }

  const { browserError } = useBrowserDetection()

  return (
    <>
      <SEO title={ seoTitle } description={ seoDescription } />
      <ThemeProvider { ...{ theme } }>
        <DatosAbiertosContextProvider>
          <IndicadoresContextProvider>
            <ModalContextProvider>
              <CssBaseline />
              <ModalController />

              <Navbar { ...{ withNav } } />
              <main className={ styles.main }>
                { browserError && <div
                  style={ {
                    margin: "16px 0 16px 0", width: "100%", textAlign: "center",
                    fontSize: "20px", fontWeight: "bold", padding: "24px", display: "flex",
                    alignItems: "center", justifyContent: "center"
                  } }
                >
                  { browserError }
                </div> }

                { children }

                { showScroll && (
                  <Button
                    aria-label="Ir hasta arriba"
                    onClick={ () =>
                      typeof window !== "undefined" &&
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className={ styles.button }
                  >
                    Volver arriba
                  </Button>
                ) }
              </main>

              <Footer />
            </ModalContextProvider>
          </IndicadoresContextProvider>
        </DatosAbiertosContextProvider>
      </ThemeProvider>
    </>
  )
}

export default React.memo(Layout)
