import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { MAX_WIDTH } from "../../_layout/Layout/Layout"

interface IStyleProps {
  HEIGHT: number
}

export default makeStyles((theme: ITheme) => ({
  topbar: {
    height: ({ HEIGHT }: IStyleProps) => HEIGHT,
    padding: theme.spacing(0, 2),
    position: "relative",

    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: MAX_WIDTH,
    },
  },
}))
