import { genericCrud } from "../../contextHelpers"
import { Dispatch, IOpendataHistoryQueryParams, IVarParams } from "../types"
import { API } from "aws-amplify"
import { API_NAME } from ".."
import { setLoadingDatosAbiertos } from "./helpers"

const getOpendataHistory = (dispatch: Dispatch) => async (
  params: IOpendataHistoryQueryParams
) => {
  const successCallback = async (data: any) => {
    dispatch({
      type: "set_datos_abiertos",
      payload: {
        datosAbiertos: data || [],
        datosAbiertosLastFetch: new Date().getTime(),
      },
    })
  }

  await setLoadingDatosAbiertos(dispatch)(true)
  await genericCrud(
    dispatch,
    API.get(API_NAME, "/opendata-history", {
      headers: {
        "Content-Type": "application/json",
      },
      queryStringParameters: params,
    }),
    successCallback
  )
  await setLoadingDatosAbiertos(dispatch)(false)
}

const getOpendataHistoryPeriodos = (dispatch: Dispatch) => async (
) => {
  const successCallback = async (data: any) => {
    dispatch({
      type: "set_periodos",
      payload: {
        periodos: data,
      },
    })
  }

  await genericCrud(
    dispatch,
    API.get(API_NAME, "/opendata-history-periodos", {
      headers: {
        "Content-Type": "application/json",
      }
    }),
    successCallback
  )
}

export { getOpendataHistory, getOpendataHistoryPeriodos }
