import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  root: {
    borderLeft: theme.spacing(3),
    borderColor: theme.palette.text.lightText,
    display: "flex",
    flexDirection: "column",
    width: '100%'
  },
  textFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  helperClass: {
    width: "100%",
    margin: theme.spacing(0.5, 1, 0, 0),
  },
}))
