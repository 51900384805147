import API from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"
import { API_NAME } from ".."
import {
  ExtractSearchVariableQuery,
  ExtractSearchVariableQueryVariables,
  ListVariablesQuery,
  ListVariablesQueryVariables
} from "../../../API"
import { extractSearchVariable, listVariables } from "../../../graphql/queries"
import { callGraphQL, GraphQLOptions } from "../../contextHelpers"
import { Dispatch, VariableType } from "../types"

const getDownloadURL = (dispatch: Dispatch) => async (
  referencia: VariableType
) => {
  const getDownloadDataByReference = (referencia: VariableType) => {
    const data = {
      opendata: {
        filename: "BD_Municipios_Portal.xlsx",
        downloadURL: "/download-counter"
      },
      historico: {
        filename: "Historico_trimestrales_Portal.xlsx",
        downloadURL: "/download-counter-opendata-history"
      },
      enif: {
        filename: "ENIF_2018_Din_Portal.xlsx",
        downloadURL: "/download-counter-enif"
      }
    }

    return data[referencia] ?? null
  }

  const { filename, downloadURL } = getDownloadDataByReference(referencia)

  const signedURL = await Storage.get(filename, {
    expires: 60
  })

  await API.get(API_NAME, downloadURL, {
    headers: {
      "Content-Type": "application/json"
    }
  })

  return signedURL.toString()
}


const getAllVariables = (dispatch: Dispatch) => async (
  referencia: VariableType
) => {
  const variableData = await callGraphQL<ListVariablesQuery,
    GraphQLOptions<ListVariablesQueryVariables>>(listVariables, {
    variables: {
      // @ts-ignore
      limit: 500
    }
  })

  const variables = variableData.data.listVariables.items.filter(
    e => e.referencia === referencia
  )
  const categorias = variables
  .map(e => e.categoria)
  .filter((v, i, a) => a.indexOf(v) === i)
  .sort()


  const sectores = variables
  .map(e => e.sector)
  .filter((v, i, a) => a.indexOf(v) === i)
  .sort()


  const dispatchVariables = (referencia: VariableType) => {
    const actions = {
      opendata: () => {
        dispatch({
          type: "set_variables",
          payload: {
            variables,
            sectores,
            categorias
          }
        })
        return true
      },
      historico: () => {
        dispatch({
          type: "set_history_variables",
          payload: {
            historyVariables: variables,
            sectores,
            categorias
          }
        })
        return true
      }
    }

    return actions[referencia]?.() ?? "Dispatch no reconocido."
  }

  dispatchVariables(referencia)
}

const getDescripciones = (dispatch: Dispatch) => async (
  categoria: string,
  sector: string,
  referencia: VariableType
) => {
  const variableQueryResult = await callGraphQL<ExtractSearchVariableQuery,
    ExtractSearchVariableQueryVariables>(extractSearchVariable, {
    variables: {
      categoria,
      sectorDescripcion: { beginsWith: { sector } }
    }
  })
  const variables = variableQueryResult.data.extractSearchVariable.items.filter(
    e => e.referencia === referencia
  )
  const descripciones = variables
  .map(e => e.descripcion)
  .filter((v, i, a) => a.indexOf(v) === i)
  .sort()

  dispatch({
    type: "set_descripciones",
    payload: {
      descripciones
    }
  })
}

export {
  getDownloadURL,
  getAllVariables,
  getDescripciones
}
