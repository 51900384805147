import { Grid } from "@material-ui/core"
import * as React from "react"
import { ReactNode } from "react"
import { SectionContainer, SectionTitle } from ".."

import useStyles from "./styles"

interface IDividedSection {
  components: ReactNode[]
  reversed?: boolean
}

const DividedSection: React.FC<IDividedSection> = ({
                                                     components,
                                                     reversed
                                                   }) => {
  const styles = useStyles({ reversed })

  return (
      <Grid container className={ styles.root }>
        { components.map((e, i) => (
          <Grid key={ i } container item xs={ 12 } md={ 6 }>
            { e }
          </Grid>
        )) }
      </Grid>
  )
}

export default DividedSection
