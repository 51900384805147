import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { MAX_WIDTH } from "../Layout/Layout"

export default makeStyles((theme: ITheme) => ({
  root: {
    background: theme.palette.background.darkBackground
  },

  footer: {
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      maxWidth: MAX_WIDTH
    }
  },

  links: {
    padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    transition: theme.palette.extras.transitions.default,
    "& a": {
      padding: theme.spacing(1),
      margin: theme.spacing(0),
      color: theme.palette.text.lightText,
      transition: theme.palette.extras.transitions.default,
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 2),
        borderRight: `1px solid ${ theme.palette.text.disabled }`
      }
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      "& :hover": {
        fontWeight: theme.typography.fontWeightBold
      },
      "& a:last-child": {
        borderRight: "none"
      }
    }
  },
  icons: {
    "& button": {
      display: "inline-block",
      margin: theme.spacing(2),
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.text.lightText,
      transition: theme.palette.extras.transitions.default
    },
    [theme.breakpoints.up("md")]: {
      "& :hover": {
        color: theme.palette.secondary.main
      }
    }
  },
  copyright: {
    letterSpacing: theme.letterspacing(0.75),
    lineHeight: 2,
    textTransform: "uppercase",
    color: theme.palette.text.lightText,
    fontSize: theme.typography.body2.fontSize
  },

  subheader: {
    padding: theme.spacing(0),
    lineHeight: theme.spacing(0.15),
    [theme.breakpoints.up("md")]: {
      minHeight: 50,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end"
    }
  },


  // ******************************* //

  listOfSections: {
    display: "flex",
    width: "100%",
    textAlign: "left",
    flexWrap: "wrap"
  },

  section: {
    width: "100%",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    "& ul": {
      padding: theme.spacing(0)
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0,1),
      width: "20%",
      maxWidth: "20%"
    }
  },

  sectionItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(0.5, 0)
  },

  sectionTitleDivider: {
    width: "75%",
    backgroundColor: theme.palette.text.lightText,
    height: theme.spacing(0.25)
  },

  sectionTitle: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.text.lightText,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",

  },

  sectionItemLink: {
    textDecoration: "none",
    color: theme.palette.text.lightText,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightLight
  },


  developedBy: {
    color: theme.palette.text.lightMild
  }
}))
