import { FC } from "react"
import * as React from "react"
import { Grid, Typography } from "@material-ui/core"

import useStyles from "./styles"
import { Loader } from ".."

interface IActivityLoader {
  text: string
  light?: boolean
}

const ActivityLoader: FC<IActivityLoader> = ({ text, light }) => {
  const styles = useStyles()

  return (
    <Grid
      container
      item
      xs
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Loader {...{ light }} />
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          className={light ? `${styles.text} ${styles.lightText}` : styles.text}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ActivityLoader
