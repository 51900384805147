import createMuiTheme, {
  Theme,
  ThemeOptions,
} from "@material-ui/core/styles/createMuiTheme"
import { Palette } from "@material-ui/core/styles/createPalette"

interface ITransition {
  default: string
  slowEaseInOut: string
  fastEaseInOut: string
}

interface IPalette extends Palette {
  text: {
    // TEXT
    primary: string
    secondary: string
    disabled: string
    hint: string
    darkText: string
    lightText: string
    lightMild: string
    mildText: string
    superLight: string
  }

  background: {
    // BACKGROUND
    default: string
    paper: string
    paperLight: string
    darkBackground: string
    lightBackground: string
  }

  overlays: {
    // OVERLAYS
    darkOverlay: string
    overlay: string
    lightOverlay: string
    whiteOverlay: string
    gradientOverlay: string
  }

  status: {
    // STATUS
    danger: string
    success: string
    warning: string
  }

  extras: {
    // EXTRAS
    none: string
    darkBlue: string
    orange: string
    blueType1: string
    blueType2: string
    yellow: string
    olive: string
    darkOlive: string
    darkBlueType1: string
    //TRANSITIONS
    transitions: ITransition
  }
}
export interface ITheme extends Theme {
  palette: IPalette
  roundness: number
  hairlineWidth: number
  letterspacing: (spacing: number) => string
  lineHeight: (spacing: number) => string
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette
  roundness: number
  hairlineWidth: number
  letterspacing: (spacing: number) => string
  lineHeight: (spacing: number) => string
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#455F72",
    },

    secondary: {
      main: "#455F72",
    },

    text: {
      // TEXT
      primary: "#191F27",
      secondary: "#B38E5D",
      hint: "#90B29B",
      disabled: "rgba(51, 51, 51, 0.75)",

      darkText: "#00142b",
      lightText: "white",
      lightMild: "#d9d9d9",
      mildText: "rgba(51, 51, 51, 0.5)",
      superLight: "rgba(51, 51, 51, 0.3)",
    },

    background: {
      // BACKGROUND
      default: "white",
      paper: "white",
      paperLight: "#F3F3F3",
      darkBackground: "#455F72",
      lightBackground: "white",
    },

    overlays: {
      // OVERLAYS
      darkOverlay: "rgba(0,0,0,0.75)",
      overlay: "rgba(0,0,0,0.50)",
      lightOverlay: "rgba(0,0,0,0.20)",
      whiteOverlay: "rgba(255,255,255,0.35)",
      gradientOverlay:
        "linear-gradient(180deg, rgba(69, 95, 114) 70%, rgba(255,255,255,1) 100%)",
    },

    status: {
      // STATUS
      danger: "#db4848",
      success: "#009966",
      warning: "#F0D398",
    },

    extras: {
      // EXTRAS
      none: "transparent",
      darkBlue: "#455F72",
      orange: "#E59368",
      yellow: "#F0BE74",
      olive: "#90B29B",
      blueType1: "#64B9D0",
      blueType2: "#65B2B8",
      darkOlive: "#65806E",
      darkBlueType1: "#2B5658",

      transitions: {
        default: "all 0.3s ease-in-out",
        slowEaseInOut: "all 1.2s ease-in-out",
        fastEaseInOut: "all 0.3s ease-in-out",
      },
    },
  },
  // TYPOGRAPHY
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
  },
  // UTILITIES
  roundness: 4,
  hairlineWidth: 1,
  letterspacing: num => `${num}px`,
  lineHeight: num => `${num}rem`,
} as IThemeOptions) as ITheme

export default theme
