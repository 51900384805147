import { Grid } from "@material-ui/core"
import * as React from "react"
import { FC, useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import { Button, RichTextRenderer } from ".."
import { useInterval } from "../../../hooks"
import { SectionContainer, SectionTitle } from "../../_layout"

import useStyles from "./styles"

export interface IDidYouKnow {
  id: string
  text: string
  contenido: string
}

interface IDidYouKnowProps {
  sectionID: string
  tips: IDidYouKnow[]
}

const DidYouKnow: FC<IDidYouKnowProps> = ({ sectionID, tips }) => {
  const styles = useStyles()
  const [tip, setTip] = useState("")

  useInterval(() => {
    getRdmTip()
  }, 5000)

  const getRdmTip = () => {
    const item = tips[Math.floor(Math.random() * tips.length)]

    if (item) {
      setTip(item.contenido)
    }
    return
  }

  useEffect(() => {
    getRdmTip()
  }, [])

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title="Sabías que..." />
      <Grid container className={ styles.root }>
        <Grid item  className={ styles.text }>
          <Fade>{ tip && <RichTextRenderer big content={ tip } /> }</Fade>
        </Grid>

        <Grid item container className={ styles.buttonContainer }>
          <Button aria="otro dato" small discrete handleClick={ getRdmTip }>
            ver otro dato...
          </Button>
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default DidYouKnow
