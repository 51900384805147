import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  centered: boolean
  dark?: boolean
}

export default makeStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

  },
  title: {
    margin: theme.spacing(1, 4),

    width: "100%",

    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    textAlign: ({ centered }: IStyleProps) => (centered ? "center" : "left"),

    color: ({ dark }: IStyleProps) =>
      dark
        ? theme.palette.text.lightText
        : theme.palette.text.primary,

    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}))
