import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  introWrapper: {
    // flexDirection: "column",
    padding: theme.spacing(1,0),
    flexWrap: 'wrap',
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2, 1, 2),
      flexDirection: "row"
    }
  },

  iconWrapper: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.main
  },

  instructionsWrapper: {
    display: "flex",
    alignItems: 'center',
    padding: theme.spacing(0, 3)
  }
}))
