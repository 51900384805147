import * as React from "react"

export default {
  renderText: (text: any) =>
    text
      .split("\n")
      .flatMap((text: string, i: number) => [i > 0 && <br key={i} />, text]),
  renderNode: {
    "embedded-asset-block": (node: any) => {
      return (
        <div>
          {node.data.target.fields && (
            <img
              width="80%"
              src={node.data.target.fields.file["en-US"].url}
              alt="imagen"
            />
          )}
        </div>
      )
    },
  },
}
