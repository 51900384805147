import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.extras.yellow,
    borderRadius: theme.roundness,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    height: 500,
    [theme.breakpoints.up("md")]: {
      height: 'auto'
    }
  },

  title: {
    margin: theme.spacing(1, 0),

    "& h5": {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h4.fontSize
    }
  },

  text: {
    margin: theme.spacing(0, 0)
  },

  buttonContainer: {
    justifyContent: "flex-end"
  }
}))
