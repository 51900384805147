import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { AiFillTwitterCircle } from "react-icons/ai"
import { FaFacebook, FaLinkedin } from "react-icons/fa"
import { IoLogoWhatsapp } from "react-icons/io"

import { MdEmail } from "react-icons/md"

// @ts-ignore
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share"

import useStyles from "./styles"

interface ISocialShareIcons {
  text: string
  shareTitle: string
  url: string
}

const SocialShareIcons: React.FC<ISocialShareIcons> = ({
                                                         text,
                                                         shareTitle,
                                                         url
                                                       }) => {
  const {
    socialButtons,
    socialButton,
    socialWrapper,
    socialTitle
  } = useStyles()

  return (
    <Grid container className={ socialWrapper }>
      <Grid item className={ socialTitle }>
        <Typography component="span">{ text }</Typography>
      </Grid>
      <Grid container item className={ socialButtons }>
        {/*
 // @ts-ignore */ }
        <FacebookShareButton
          quote={ shareTitle }
          className={ socialButton }
          url={ url }
        >
          <span>
            <FaFacebook />
          </span>
        </FacebookShareButton>
        {/*
 // @ts-ignore */ }
        <TwitterShareButton
          title={ shareTitle }
          className={ socialButton }
          url={ url }
        >
          <span>
            <AiFillTwitterCircle />
          </span>
        </TwitterShareButton>
        {/*
 // @ts-ignore */ }
        <WhatsappShareButton
          title={ shareTitle }
          className={ socialButton }
          url={ url }
        >
          <span>
            <IoLogoWhatsapp />
          </span>
        </WhatsappShareButton>
        {/*
 // @ts-ignore */ }
        <LinkedinShareButton
          title={ shareTitle }
          source={ url }
          className={ socialButton }
          url={ url }
        >
          <span>
            <FaLinkedin />
          </span>
        </LinkedinShareButton>
        {/*
 // @ts-ignore */ }
        <EmailShareButton
          subject={ shareTitle }
          className={ socialButton }
          url={ url }
        >
          <span>
            <MdEmail />
          </span>
        </EmailShareButton>
      </Grid>
    </Grid>
  )
}

export default SocialShareIcons
