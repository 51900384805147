import { useRef, useEffect } from 'react';
import { Callback } from '../helpers/Types';

type HookReturnType = void;

export default (callback: Callback, delay: number): HookReturnType => {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
