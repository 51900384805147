/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVariable = /* GraphQL */ `
  query GetVariable($variable: String!) {
    getVariable(variable: $variable) {
      variable
      descripcion
      categoria
      sector
      tipo
      referencia
      createdAt
      updatedAt
    }
  }
`;
export const listVariables = /* GraphQL */ `
  query ListVariables(
    $variable: String
    $filter: ModelVariableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVariables(
      variable: $variable
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        variable
        descripcion
        categoria
        sector
        tipo
        referencia
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const extractSearchVariable = /* GraphQL */ `
  query ExtractSearchVariable(
    $categoria: String
    $sectorDescripcion: ModelVariableByCategoriaBySectorByDescripcionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extractSearchVariable(
      categoria: $categoria
      sectorDescripcion: $sectorDescripcion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        variable
        descripcion
        categoria
        sector
        tipo
        referencia
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVariableEnif = /* GraphQL */ `
  query GetVariableEnif($cve_pregunta: String!) {
    getVariableEnif(cve_pregunta: $cve_pregunta) {
      cve_pregunta
      seccion
      pregunta
      createdAt
      updatedAt
    }
  }
`;
export const listVariableEnifs = /* GraphQL */ `
  query ListVariableEnifs(
    $cve_pregunta: String
    $filter: ModelVariableEnifFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVariableEnifs(
      cve_pregunta: $cve_pregunta
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cve_pregunta
        seccion
        pregunta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const extractSearchVariableEnif = /* GraphQL */ `
  query ExtractSearchVariableEnif(
    $seccion: String
    $pregunta: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariableEnifFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extractSearchVariableEnif(
      seccion: $seccion
      pregunta: $pregunta
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cve_pregunta
        seccion
        pregunta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVariableCategoriaEnif = /* GraphQL */ `
  query GetVariableCategoriaEnif($cve_cat: String!) {
    getVariableCategoriaEnif(cve_cat: $cve_cat) {
      cve_cat
      categoria
      createdAt
      updatedAt
    }
  }
`;
export const listVariableCategoriaEnifs = /* GraphQL */ `
  query ListVariableCategoriaEnifs(
    $cve_cat: String
    $filter: ModelVariableCategoriaEnifFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVariableCategoriaEnifs(
      cve_cat: $cve_cat
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cve_cat
        categoria
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($email: String!) {
    getNewsletter(email: $email) {
      email
      name
      createdAt
      updatedAt
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $email: String
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNewsletters(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
