// noinspection DuplicatedCode

import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { HEIGHT, MAX_WIDTH } from "../../_layout/Layout/Layout"

export default makeStyles((theme: ITheme) => ({
  root: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: MAX_WIDTH
    }
  },

  absoluteLayout: {},

  sliderClickZone: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    position: "absolute",
    zIndex: 1002
  },

  slider: {},

  slideItem: {
    position: "relative",
    width: "100%",
    height: "100%"
  },


  slideContent: {
    position: "absolute",
    bottom: "25%",
    left: "8%",
    zIndex: 999,
    [theme.breakpoints.up("sm")]: {
      bottom: "15%",
      left: "10%"
    }
  },

  slideTitle: {
    maxWidth: "85%",
    background: theme.palette.overlays.overlay,
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    "& h3": {
      color: theme.palette.text.lightText,
      fontSize: 20,
      margin: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
        fontSize: 40
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 60
      }
    }
  },

  slideSubtitle: {
    maxWidth: "70%",
    background: theme.palette.overlays.darkOverlay,
    padding: theme.spacing(0.25, 1),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    "& h3": {
      color: theme.palette.text.lightText,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      margin: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0.25, 2),
        fontSize: 28
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 50
      }
    }
  },

  arrowNext: {
    padding: 0,
    border: "none",
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h6.fontSize,
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
    marginRight: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    right: 0,
    zIndex: 8000,
    backgroundColor: "white",
    "& :active": {
      borderRadius: "50%"
    },

    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      height: theme.spacing(6),
      width: theme.spacing(6),
      fontSize: theme.typography.h4.fontSize,
      marginRight: 20
    }
  },

  arrowPrev: {
    padding: 0,
    border: "none",
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h6.fontSize,
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
    marginLeft: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: 0,
    zIndex: 8000,
    backgroundColor: "white",
    "& :active": {
      borderRadius: "50%"
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      height: theme.spacing(6),
      width: theme.spacing(6),
      fontSize: theme.typography.h4.fontSize,
      marginLeft: 20
    }
  },

  arrowClass: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  dots: {
    justifyContent: "center",
    padding: theme.spacing(2),
    margin: 0,
    width: "100%",
    listStyle: "none",
    textAlign: "center",

    "& li": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
      padding: 0,
      cursor: "pointer",

      [theme.breakpoints.up("md")]: {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5)
      }
    },
    "& li button": {
      fontSize: 0,
      lineHeight: theme.lineHeight(0),
      display: "block",
      width: theme.spacing(1),
      height: theme.spacing(1),
      padding: theme.spacing(0.5),
      cursor: "pointer",
      color: "transparent",
      border: 1,
      background: theme.palette.overlays.lightOverlay,
      borderRadius: "50%",
      [theme.breakpoints.up("md")]: {
        height: theme.spacing(2),
        width: theme.spacing(2)
      }
    },
    "& li button:hover, li button:focus ": {
      outline: "none"
    },
    "& li button:hover:before, li button:focus:before": {
      opacity: 1
    },
    "& li button:before": {
      fontFamily: "slick",
      fontSize: "6px",
      lineHeight: theme.lineHeight(20),

      position: "absolute",
      top: 0,
      left: 0,

      width: theme.spacing(2.5),
      height: theme.spacing(2.5),

      content: "•",
      textAlign: "center",

      opacity: ".25",
      color: "black"
    },
    "& li.active button": {
      background: theme.palette.text.lightText, border: `1px ${ theme.palette.secondary.main } solid`
    },
    "& li.active button:before": {
      opacity: ".75",
      color: "black"
    }
  },

  image: {
    width: "100%",
    height: 384,
    position: "relative",
    zIndex: 1,

    [theme.breakpoints.up("md")]: {
      minHeight: `calc(100vh - ${ HEIGHT * 2 }px)`
    }
  },

  content: {
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    textAlign: "center",
    padding: theme.spacing(2),

    zIndex: 1000,
    color: theme.palette.text.lightText,

    "& h6": {
      margin: theme.spacing(4, 0),
      fontSize: theme.typography.body1.fontSize,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h6.fontSize
      }
    },

    "& h4": {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h4.fontSize
      }
    },

    "& h3": {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,

      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h3.fontSize
      }
    }
  },

  overlay: {
    background: theme.palette.overlays.lightOverlay
  }
}))
