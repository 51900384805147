import createDataContext from '../createDataContext'
import modalReducer, { ReducerType } from './reducer'
import { Actions, StateElems } from './types'

import * as Main from './methods/main'
export * from './types'

export const { Provider, Context } = createDataContext<
  StateElems,
  Actions,
  ReducerType
>(
  modalReducer,
  {
    openModal: Main.openModal,
    closeModal: Main.closeModal,
  },
  { hidden: true, component: null, title: '' }
)
