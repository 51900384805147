import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(0.5),
  },
  required: {
    color: theme.palette.status.danger,
    marginLeft: theme.spacing(0.5),
    height: "100%",
  },
}))
