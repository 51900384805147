import { TextField, withStyles } from "@material-ui/core"
import theme from "../../../App.theme"

interface IStyleProps {
  multiline: boolean
  disabled?: boolean
}

export default withStyles(() => ({
  root: {
    backgroundColor: "red",
    borderRadius: theme.roundness,

    "& .MuiOutlinedInput-multiline": {
      padding: theme.spacing(0),
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
      fontSize: theme.typography.body2.fontSize,

      "& fieldset": {
        border: `1px ${theme.palette.text.disabled} solid`,
        borderRadius: theme.roundness,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.roundness,
      color: ({ disabled }: IStyleProps) =>
        disabled ? theme.palette.text.disabled : theme.palette.text.primary,
      "&.focused": {
        backgroundColor: "red",
      },
    },
  },
}))(TextField)
