import { useCallback, useMemo } from "react"
import { IChip, IDataTableConfig } from "../components/_dataMgmt/DataTable/DataTable"

import paginatorFunc from "../helpers/paginator"

export interface ISearchParams {
  id: number
  field: string
  colProp: string
  chip?: IChip
}

interface IHelpersHookProps {
  searchParamsList: ISearchParams[]
  pagina?: number
  numPag?: number
}

export interface IHookHelpersHook extends Partial<IDataTableConfig> {
  paginator: () => (string | number)[]
  getNow: () => number
}

export default ({
  pagina,
  numPag,
  searchParamsList,
}: IHelpersHookProps): IHookHelpersHook => {
  return {
    getNow: useCallback(() => {
      return new Date().getTime()
    }, []),

    paginator: useCallback(() => paginatorFunc(pagina, numPag), [
      pagina,
      numPag,
    ]),

    listTitles: useMemo(() => searchParamsList.map(e => e.field), [
      searchParamsList,
    ]),

    colProps: useMemo(() => searchParamsList.map(e => e.colProp), [
      searchParamsList,
    ]),

    chips: useMemo(
      () =>
        searchParamsList.map(e => {
          if (e.chip && e.chip.active) {
            return e.chip
          }
          return undefined
        }),
      [searchParamsList]
    ),
  }
}
