import { makeStyles } from "@material-ui/core"
import { FluidObject } from "gatsby-image"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  image: FluidObject
  imageBG: string
  imgRoute?: string
}

export default makeStyles((theme: ITheme) => ({
  imgRoot: {
    alignItems: "center",
    justifyContent: "center",
    cursor: ({ imgRoute }: IStyleProps) => imgRoute ? "pointer" : "default"
  },

  imgContainer: {
    padding: theme.spacing(0.25),
    backgroundColor: ({ imageBG }: IStyleProps) => imageBG,
    borderRadius: theme.roundness,

    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },

    [theme.breakpoints.up("md")]: {
      width: "75%"
    }
  },

  img: {
    flexGrow: 1,
    borderRadius: theme.roundness
  },

  btnContainer: {
    justifyContent: "center",
    padding: theme.spacing(4, 0)
  },

  leftPanel: {
    [theme.breakpoints.up("md")]: {
      padding:  theme.spacing(0),

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  },

  article: {
    "& p": {
      lineHeight: theme.lineHeight(2),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body1.fontSize
      }
    }
  }
}))
