import { useContext, useEffect, useCallback } from "react"
import {
  Actions,
  Context as IndicadoresContext,
  IIndicadoresContextType,
  StateElems,
} from "../context/IndicadoresContext"

interface IIndicadoresHook extends Partial<StateElems>, Partial<Actions> {
  fetchIndicadores: () => Promise<void>
}

export default (): IIndicadoresHook => {
  const {
    state: { indicadores, loadingIndicadores },
    getIndicadores,
    getDownloadURL,
  } = useContext<IIndicadoresContextType>(IndicadoresContext)

  const fetchIndicadores: () => Promise<void> = useCallback(async () => {
    await getIndicadores()
  }, [getIndicadores])

  useEffect(() => {
    if (indicadores.length === 0) {
      fetchIndicadores()
    }
  }, [indicadores])

  return {
    indicadores,
    fetchIndicadores,
    loadingIndicadores,
    getDownloadURL,
  }
}
