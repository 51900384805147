import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  light: boolean
}

export default makeStyles((theme: ITheme) => ({
  root: {
    background: ({ light }: IStyleProps) => light ? theme.palette.background.paper : theme.palette.primary.main,
    boxShadow: theme.shadows[0],
    borderTop: "0px solid black",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(1, 0),
    width: "100%"
  },
  heading: {
    justifyContent: "flex-start",
    alignItems: "center",

    "& h6": {
      width: "100%",
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: ({ light }: IStyleProps) => light ? theme.palette.text.primary : theme.palette.text.lightText,
      textTransform: "none"
    }
  }
}))
