import * as React from "react"

import { FormErrorMessage, FormItemLayout } from ".."
import { TAnyCallback } from "../../../helpers/Types"
import CssTextField from "./styles"

interface IFormTextInput {
  name: string
  label: string
  onChange: TAnyCallback
  value: string
  errorValue: string
  helper?: string
  type?: string
  required?: boolean
  textArea?: boolean
  placeholder?: string
}

const FormTextInput = React.forwardRef((props: IFormTextInput, ref: any) => {
  const {
    label,
    required,
    textArea,
    onChange,
    value,
    errorValue,
    helper,
    type = "text",
    name,
    placeholder,
    ...textFieldProps
  } = props

  return (
    <>
      <FormItemLayout { ...{ name, helper, required, label, textArea } }>
        <CssTextField
          inputRef={ref}
          fullWidth
          multiline={ textArea }
          rows={ 4 }
          value={ value === null && type === "text" ? "-" : value || "" }
          variant="outlined"
          onChange={ onChange }
          autoComplete="off"
          { ...{ type, name, placeholder } }
          { ...textFieldProps }
        />
      </FormItemLayout>
      { <FormErrorMessage errorMessage={ errorValue } /> }
    </>
  )
})

export default FormTextInput
