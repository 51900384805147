import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  width: number
}

export default makeStyles((theme: ITheme) => ({
  img: {
    width: ({ width }: IStyleProps) => (width ? width : "100%"),
    minWidth: theme.spacing(4),
  },
}))
