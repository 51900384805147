import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  dark: boolean
}

export default makeStyles((theme: ITheme) => ({
  tabsRootWrapper: {
    flexDirection: "column",
    backgroundColor: ({ dark }: IStyleProps) => dark ? theme.palette.primary.main : "none",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
    color: ({ dark }: IStyleProps) => dark ? theme.palette.text.lightText : theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      margin: theme.spacing(0),
      borderRadius: theme.spacing(0.5),
    }
  },

  tabWrapper: {
    width: "100%",

    "& .MuiTabs-flexContainer": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        alignItems: "flex-start"
      }
    },

    "& .MuiTabs-indicator": {
      height: theme.spacing(0.15),
      background: theme.palette.primary.main
    },
    "& .MuiTab-root": {}
  },

  tabsText: {
    width: "100%",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
      width: "auto"
    }
  },


  tab: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: "none",
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5),
    margin: theme.spacing(0, 0.5)

  },
  selectedTab: {
    fontWeight: theme.typography.fontWeightBold
  },
  unselectedTab: {
    color: ({ dark }: IStyleProps) => dark ? theme.palette.text.lightText : theme.palette.text.disabled
  },

  tabPanel: {
    height: `calc(100% - 48px)`
  }
}))
