import createDataContext from "../createDataContext"
import entriesReducer, { ReducerType } from "./reducer"
import { Actions, StateElems } from "./types"

import * as Main from "./methods/main"
import * as Helpers from "./methods/helpers"
export * from "./types"

export const { Provider, Context } = createDataContext<
  StateElems,
  Actions,
  ReducerType
>(
  entriesReducer,
  {
    // Main
    getIndicadores: Main.getIndicadores,
    getDownloadURL: Main.getDownloadURL,

    // Helpers
    setLoadingIndicadores: Helpers.setLoadingIndicadores,
  },
  {
    indicadores: [],
    loadingIndicadores: false,

    errorMessage: "",
    successMessage: "",
  }
)
