import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  controllerContainer: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      padding: theme.spacing(2, 0),
    },
  },

  itemNavigatorContainer: {
    padding: theme.spacing(2, 0),
    borderRadius: theme.roundness,
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
      padding: theme.spacing(0),
    },
  },

  darkBg: {
    backgroundColor: theme.palette.primary.main,
  },
}))
