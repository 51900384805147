import API, { GraphQLResult } from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"
import * as R from "ramda"
import {
  BatchAddVariablesMutationVariables,
  CreateVariableInput,
  ExtractSearchVariableQuery,
  Variable
} from "../../../API"
import { batchAddVariables, createVariableEnif, updateVariableEnif } from "../../../graphql/mutations"
import { asyncForEach } from "../../../helpers/helperFunctions"
import { Dispatch, FileCB, VariableType } from "../types"

export const reader = typeof window !== "undefined" && new window.FileReader()

const setLoadingDatosAbiertos = (dispatch: Dispatch) => async (
  status: boolean
) => {
  dispatch({ type: "set_loading", payload: { loadingDatosAbiertos: status } })
}


const resetDatosAbiertos = (dispatch: Dispatch) => async () => {
  dispatch({
    type: "set_datos_abiertos",
    payload: {
      datosAbiertos: [],
      datosAbiertosLastFetch: null,
      pagina: 1,
      numPag: 1,
      numReg: 0,
      registrosPorPagina: 5
    }
  })
}

const resetVariables = (dispatch: Dispatch) => async () => {
  dispatch({
    type: "set_variables",
    payload: {
      variables: null,
      sectores: null,
      categorias: null
    }
  })

  dispatch({
    type: "set_history_variables",
    payload: {
      historyVariables: null,
      sectores: null,
      categorias: null
    }
  })
}

const mapVars = (
  variableQuery: GraphQLResult<ExtractSearchVariableQuery>
): Variable[] => {
  const items = variableQuery.data?.extractSearchVariable?.items

  if (items) {
    items.map(
      variable =>
        ({
          variable: variable.variable
        } as Variable)
    )
  }

  return items || []
}


const saveS3File = async (file: File, cb: FileCB) => {
  await Storage.put(file.name, file, {
    level: "public",
    progressCallback(progress: ProgressEvent) {
      cb(Math.ceil((progress.loaded / progress.total) * 100))
    }
  })
}

const uploadVariablesToAppSyncDB = async (
  variables: BatchAddVariablesMutationVariables,
  referencia: VariableType
) => {

  const vars = variables.variables

  if (referencia === "enif") {
    const start = async () => {

      await asyncForEach(vars, async (variable) => {
        try {
          await API.graphql({
            query: createVariableEnif,
            variables: { input: variable },
            // @ts-ignore
            authMode: "AMAZON_COGNITO_USER_POOLS"
          })
        } catch {
          await API.graphql({
            query: updateVariableEnif,
            variables: { input: variable },
            // @ts-ignore
            authMode: "AMAZON_COGNITO_USER_POOLS"
          })
        }
      })
    }

    await start()


  } else {
    const chunks = R.splitEvery(25, vars)
    const start = async () => {
      await asyncForEach<CreateVariableInput[]>(
        chunks,
        async (chunk: CreateVariableInput[]) => {
          await API.graphql({
            query: batchAddVariables,
            variables: { variables: chunk },
            // @ts-ignore
            authMode: "AMAZON_COGNITO_USER_POOLS"
          })
        }
      )
    }

    await start()
  }


}

export {
  setLoadingDatosAbiertos,
  mapVars,
  saveS3File,
  resetDatosAbiertos,
  resetVariables,
  uploadVariablesToAppSyncDB
}
