import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  logosContainer: {
    alignItems: "center",
    justifyContent: "center",
  },

  titleContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    visibility: "hidden",
    color: theme.palette.text.primary,

    [theme.breakpoints.up("md")]: {
      visibility: "visible",
    },
  },

  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
    fontSize: theme.typography.body1.fontSize,
  },

  subtitle: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
  },

  imgContainer: {
    padding: theme.spacing(0.5, 0),
    "& a": {
      display: "block",
      width: "75%"
  }}
}))
