import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  disabled?: boolean
  small?: boolean
  fullWidth?: boolean
  light?: boolean
  discrete?: boolean
}

export default makeStyles((theme: ITheme) => ({
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: (props: IStyleProps) => (props.fullWidth ? "100%" : "auto"),
    height: ({ small }: IStyleProps) => small ? theme.spacing(4.5) : 'auto',

    padding: (props: IStyleProps) =>
      props.discrete
        ? theme.spacing(0)
        : props.small
        ? theme.spacing(0.5, 0.75)
        : theme.spacing(1, 3.5),

    background: (props: IStyleProps) =>
      props.disabled ? theme.palette.text.lightMild : theme.palette.extras.none,

    border: ({ light, discrete, disabled }: IStyleProps) =>
      disabled
        ? theme.palette.text.disabled
        : !discrete
        ? `1px solid ${
            light ? theme.palette.text.lightText : theme.palette.text.primary
          }`
        : "",
    borderRadius: theme.roundness,

    fontSize: theme.typography.body2.fontSize,
    textTransform: ({ small }: IStyleProps) => (small ? "none" : "uppercase"),
    textDecoration: "none",
    color: ({ light }: IStyleProps) =>
      light ? theme.palette.text.lightText : theme.palette.text.primary,
    lineHeight: theme.lineHeight(1.75),
    letterSpacing: ({ small }: IStyleProps) =>
      small ? theme.letterspacing(0.3) : theme.letterspacing(0.75),
    transition: theme.palette.extras.transitions.default,

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.body2.fontSize,
      "&:hover": {
        background: ({ discrete }: IStyleProps) =>
          !discrete ? theme.palette.secondary.main : theme.palette.extras.none,
        color: theme.palette.text.lightText,
        borderColor: theme.palette.text.lightText,
      },
    },
  },
}))
