import API from "@aws-amplify/api"
import { CreateVariableInput } from "../API"
import { createVariableCategoriaEnif, updateVariableCategoriaEnif } from "../graphql/mutations"
import { asyncForEach } from "../helpers/helperFunctions"
import { VariableType } from "../context/DatosAbiertosContext/types"
import { reader, uploadVariablesToAppSyncDB } from "../context/DatosAbiertosContext/methods/helpers"

let XLSX

if (typeof window !== "undefined") {
  XLSX = require("xlsx")
}


const VariableFactory = (file: File, referencia: VariableType) => {

  const variableObj = (e?: CreateVariableInput) => {
    const opendataProps = {
      tipo: "",
      ...e,
      referencia,
      variable: e.variable
    }

    const referencias = {
      opendata: opendataProps,
      historico: {
        ...opendataProps,
        variable: `h_${ e.variable }`
      },
      enif: e
    }

    return referencias[referencia] ?? ""
  }


  const getWB = (file: Blob, cb: (wb: any) => void) => {
    reader.readAsBinaryString(file)
    reader.onload = evt => {
      const bstr = evt.target.result
      cb(XLSX.read(bstr, { type: "binary" }))
    }
  }


  function extracted(wb: any, dataTab: number) {
    const fileSheetName = wb.SheetNames[dataTab]
    const fileSheet = wb.Sheets[fileSheetName]
    const fileVariables: CreateVariableInput[] = XLSX.utils.sheet_to_json(
      fileSheet
    )
    return fileVariables
  }

  const uploadCat = async (fileVariables) => {
    await asyncForEach(fileVariables, async (variable) => {
      try {
        await API.graphql({
          query: createVariableCategoriaEnif,
          variables: { input: variable },
          // @ts-ignore
          authMode: "AMAZON_COGNITO_USER_POOLS"
        })
      } catch {
        await API.graphql({
          query: updateVariableCategoriaEnif,
          variables: { input: variable },
          // @ts-ignore
          authMode: "AMAZON_COGNITO_USER_POOLS"
        })
      }
    })
  }

  const upload = async (fileVariables, mapKey) => {
    await uploadVariablesToAppSyncDB({
      variables: fileVariables.map(e => variableObj(e)).filter(
        (v,
         i,
         a) => a.findIndex(t => t[mapKey] === v[mapKey]) === i)
    }, referencia)
  }

  const getVars = async (wb: any) => {
    const fileVariables = extracted(wb, 1)


    if (referencia === "enif") {
      const fileCategoriesVariables = extracted(wb, 2)
      await uploadCat(fileCategoriesVariables)

      return await upload(fileVariables, "cve_pregunta")
    }
    return await upload(fileVariables, "variable")


  }

  return {
    uploadVariables: () => getWB(file, async (w) => await getVars(w))
  }


}

export default VariableFactory


