import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core"
import Img, { FluidObject } from "gatsby-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { useState } from "react"
import Fade from "react-reveal/Fade"
import { Button } from ".."

import useStyles from "./styles"

export interface ICardItem {
  id: string
  titulo: string
  subtitulo: string
  url: string
  imagen: FluidObject
}

interface ICardProps {
  data: ICardItem
}

const CardComponent: React.FC<ICardProps> = ({ data }) => {
  const [summaryVisible, setSummaryVisible] = useState<boolean>(false)
  const [
    collapsibleInformationHeight,
    setCollapsibleInformationHeight
  ] = useState<number>(20)
  const styles = useStyles({ collapsibleInformationHeight, summaryVisible })
  const { titulo, subtitulo, url, imagen } = data

  const breakpoint = useBreakpoint()

  const navigateToDetail = () => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank")
    }
  }

  return (
    <Card className={ styles.cardRoot } elevation={ 0 }>

      <Grid container style={ { position: "relative" } }>
        <Img loading="lazy" fluid={ imagen } className={ styles.image } />
        <Grid
          className={ styles.textContainer }
          onClick={ () => {
            if (!breakpoint.sm) {
              navigateToDetail()
            }
            if (summaryVisible) {
              setSummaryVisible(false)
              setCollapsibleInformationHeight(20)
            } else {
              setSummaryVisible(true)
              setCollapsibleInformationHeight(100)
            }
          } }
          onMouseOver={ () => {
            if (!breakpoint.sm) {
              setSummaryVisible(true)
              setCollapsibleInformationHeight(100)
            }
          } }
          onMouseLeave={ () => {
            if (!breakpoint.sm) {
              setSummaryVisible(false)
              setCollapsibleInformationHeight(20)
            }
          } }
        >
          <Grid container item>
            <Typography component="span" noWrap className={styles.title}>
              { titulo }
            </Typography>
          </Grid>

          <Grid container item>
            <Fade delay={ 0 } when={ summaryVisible }>
              <Typography component="p">{ subtitulo }</Typography>
              { breakpoint.sm ? (
                <Box my={ 1 }>
                  <Button
                    aria="leer"
                    light
                    small
                    handleClick={ navigateToDetail }
                  >
                    Leer
                  </Button>
                </Box>
              ) : (
                <></>
              ) }
            </Fade>
          </Grid>
        </Grid>

      </Grid>
    </Card>
  )
}

export default CardComponent
