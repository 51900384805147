import { createObjectURL } from "blob-util"
import { Object as TObject } from "./Types"

let XLSX

if (typeof window !== "undefined") {
  XLSX = require("xlsx")
}

export const objectValuesCompare = (object1: TObject, object2: TObject) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const bytesToSize = (bytes: number): string => {
  if (bytes == 0) return "0 Byte"
  return `${ (bytes / 1024 / 1000).toFixed(2).toString() } MB`
}

export const validationStore = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  password: /^(\S){6,50}$/,
  resetToken: /^([A-Z0-9_-]){5,5}$/,
  user: /^(?![0-9._])(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/,
  nip: /^([0-9_-]){4,4}$/,
  onlyText: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
  onlyNumbers: /^[0-9]{0,10}$/,
  onlyNumbersLong: /^[0-9]{0,20}$/,
  oneNumber: /^[0-9]{1,1}$/,
  phone: /^[0-9]{10,10}$/,
  nick: /^[a-zA-Z ]{0,10}$/,
  mobileID: /^[A-Z0-9]([\w -]*[A-Z0-9])?$/,
  cp: /^[0-9]{5,5}$/,
  rfc: /^([A-ZÑ&]{3,4})(?:- )?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))(?:- )?(([A-Z\d]{2})([A\d]))?$/,
  curp: /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
}

const clone = (obj: Object) => Object.assign({}, obj)

export const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object)
  const targetKey = clonedObj[key]
  delete clonedObj[key]
  clonedObj[newKey] = targetKey
  return clonedObj
}

export const numberParser = (stringOrNum: string | number) => {
  const value =
    typeof stringOrNum === "string"
      ? // Returns string
      parseFloat(stringOrNum.replace(/[,%]/g, ""))
      : // Returns number
      stringOrNum

  const isPercentage =
    typeof stringOrNum === "string" ? stringOrNum.includes("%") : false

  return {
    value,
    isPercentage
  }
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const capitzalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const addMinutes = (minutes: number) => {
  return minutes * 60 * 1000
}

export const downloadCSV = (data: any[], title: string) => {
  const ws = XLSX.utils.json_to_sheet(data)

  /* write workbook (use type 'binary') */
  const csv = XLSX.utils.sheet_to_csv(ws)

  /* generate a download */
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const blob = new Blob([s2ab(csv)], { type: "text/csv;charset=UTF-8" })
  const blobURL = createObjectURL(blob)

  const a = document.createElement("a")
  a.download = `${ title }.csv`
  a.href = blobURL
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const normalize = (items: any[]) => {
  return (
    items
    ?.filter(e => e !== "Generales")
    .map(e => ({
      value: e,
      label: e.toString()
    })) || []
  )
}

export const asyncForEach = async <T>(array: T[], callback: any) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const asyncDataMock: (data: any[]) => Promise<any[]> = (data) => {
  return new Promise((resolve) => {
    resolve(data)
  })
}

export const getObjects = (obj, key, val) => {
  let objects = []
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue
    if (typeof obj[i] == "object") {
      objects = objects.concat(getObjects(obj[i], key, val))
    } else
      //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    if (i == key && obj[i] == val || i == key && val == "") { //
      objects.push(obj)
    } else if (obj[i] == val && key == "") {
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj)
      }
    }
  }
  return objects
}
