import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  big?: boolean
}

export default makeStyles((theme: ITheme) => ({
  article: {
    width: "100%",
    height: "100%",
    "& p": {
      lineHeight: theme.lineHeight(2),
      [theme.breakpoints.up("sm")]: {
        fontSize: ({ big }: IStyleProps) => big ? theme.typography.h6.fontSize : theme.typography.body1.fontSize
      }
    }
  }
}))
