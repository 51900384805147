import { Grid, Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { useEffect, useState } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Fade from "react-reveal/Fade"
import * as React from "react"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

import useStyles from "./styles"

export interface ICarouselSlide {
  image: FluidObject
  title: string
  textoSuperior: string
  textoMedio: string
  textoInferior: string
  url: string
  id: string
}

interface ICarousel {
  slides: ICarouselSlide[]
}

const Carousel: React.FC<ICarousel> = ({ slides }) => {
  const styles = useStyles()
  const breakpoint = useBreakpoint()

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [contador, setContador] = useState(0)

  useEffect(() => {
    if (contador && contador % 5 === 0) {
      handleArrowNext()
    }
  }, [contador])

  useEffect(() => {
    const interval = setInterval(() => {
      setContador((prev) => prev + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const handleArrowNext = () => {
    setContador(0)
    if (currentSlideIndex < slides.length - 1) {
      setCurrentSlideIndex(prevState => prevState + 1)
    } else {
      setCurrentSlideIndex(0)
    }
  }

  const handleArrowPrev = () => {
    setContador(0)
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(prevState => prevState - 1)
    } else {
      setCurrentSlideIndex(slides.length - 1)
    }
  }

  const handleClickSlide = () => {
    const currentSlide = slides[currentSlideIndex]
    const paths = currentSlide.url.split("/")

    if (currentSlide.url) {
      navigate(`/${ paths[paths.length - 1] }`)
    }

  }

  return (
    <Grid className={ styles.root }>

      <Grid container className={ styles.sliderClickZone }>
        <Grid container item xs>
          <Grid item xs={ 1 }>
            <SamplePrevArrow
              onClick={ handleArrowPrev }
              customClass={ styles.arrowPrev }
              arrowClass={ styles.arrowClass }
            />
          </Grid>

          <Grid item xs={ 10 } onClick={ handleClickSlide } style={ { position: "relative", zIndex: 8888 } } />

          <Grid item xs={ 1 }>
            <SampleNextArrow
              onClick={ handleArrowNext }
              customClass={ styles.arrowNext }
              arrowClass={ styles.arrowClass }
            />
          </Grid>
        </Grid>

        <Grid container item className={ styles.dots }>
          {
            slides.map((s, i) => (
              <li key={ s.id } className={ currentSlideIndex === i ? "active" : "" }>
                <button onClick={ () => setCurrentSlideIndex(i) } />
              </li>
            ))
          }
        </Grid>

        { !breakpoint.sm && (
          <Grid
            container
            item
            style={ {
              position: "absolute",
              zIndex: 999,
              height: "100%",
              width: "100%"
            } }
          >
            <Grid container item className="more" />
          </Grid>
        ) }

      </Grid>


      <Grid container className={ styles.slider }>
        { slides.filter((slide, index) => index === currentSlideIndex).map((e: ICarouselSlide, i: number) => (
          <Grid item key={ i } className={ styles.slideItem }>
            <Grid
              container
              className={ `${ styles.content } ${
                (e.textoSuperior || e.textoMedio || e.textoInferior) && styles.overlay
              }` }
            >
              { e.textoSuperior && (
                <Typography component="h6">{ e.textoSuperior }</Typography>
              ) }

              { e.textoMedio && (
                <Typography component="h4">{ e.textoMedio }</Typography>
              ) }

              { e.textoInferior && (
                <Typography component="h3">{ e.textoInferior }</Typography>
              ) }
            </Grid>


            <Fade ssrFadeout delay={ 100 } when={ true }>
              <Img fluid={ e.image } className={ styles.image } />
            </Fade>

          </Grid>
        )) }
      </Grid>


    </Grid>
  )
}

interface ICarouselSlideArrow {
  arrowClass: string
  customClass: string
  onClick: () => void
}

const SampleNextArrow: React.FC<ICarouselSlideArrow> = props => {
  const { customClass, arrowClass, onClick } = props
  return (
    <div id="next-arrow" className={ customClass } { ...{ onClick } }>
      <div className={ arrowClass }>
        <RiArrowRightSLine />
      </div>
    </div>
  )
}

const SamplePrevArrow: React.FC<ICarouselSlideArrow> = props => {
  const { customClass, arrowClass, onClick } = props
  return (
    <button id="prev-arrow" className={ customClass } { ...{ onClick } }>
      <div className={ arrowClass }>
        <RiArrowLeftSLine />
      </div>
    </button>
  )
}

export default Carousel
