import { IDataIndicador } from ".."
import { genericCrud } from "../../contextHelpers"
import { Dispatch } from "../types"
import { setLoadingIndicadores } from "./helpers"
import { renameKey } from "../../../helpers/helperFunctions"
import { API_NAME } from "../../DatosAbiertosContext"
import API from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"

const renamedSubgroups = (data: Partial<IDataIndicador>[]) => {
  let subgroup = data.reduce(
    (r: Partial<IDataIndicador>, a: Partial<IDataIndicador>) => {
      r[a.id] = [...(r[a.id] || []), a]
      return r
    },
    {}
  )

  for (const item in subgroup) {
    subgroup = renameKey(subgroup, item, subgroup[item][0].title)
  }

  return Object.keys(subgroup).map(key => ({
    id: key,
    title: key,
    descripcion: subgroup[key][0].pnif.split(":")[1],
    data: subgroup[key],
  }))
}

const indicadoresAdapter = (data: IDataIndicador[]) => {
  const idMapping = data.map(e => ({
    id: e.id_indicador.split("-")[0],
    sub_id: e.id_indicador.split("-")[1],
    parent_id: e.id_indicador.split("-")[0].split("")[0],
    title: e.pnif.split(":")[0],
    ...e,
  }))

  let group = idMapping.reduce((r, a) => {
    r[a.parent_id] = [...(r[a.parent_id] || []), a]
    return r
  }, {})

  for (const item in group) {
    group = renameKey(group, item, group[item][0].title)
  }

  const renamedGroups = Object.keys(group).map(key => ({
    id: group[key][0].parent_id,
    title: key,
    data: renamedSubgroups(group[key]),
  }))

  return renamedGroups
}

const getIndicadores = (dispatch: Dispatch) => async () => {
  const successCallback = async (data: IDataIndicador[]) => {
    const dataIndicadores = indicadoresAdapter(data)

    dispatch({
      type: "set_indicadores",
      payload: {
        indicadores: dataIndicadores,
      },
    })
  }

  await setLoadingIndicadores(dispatch)(true)
  await genericCrud(
    dispatch,
    API.get(API_NAME, "/indicadores", {
      headers: {
        "Content-Type": "application/json",
      },
    }),
    successCallback
  )
  await setLoadingIndicadores(dispatch)(false)
}

const getDownloadURL = (dispatch: Dispatch) => async () => {
  const signedURL = await Storage.get("Indicadores.xlsx", {
    expires: 60,
  })

  await API.get(API_NAME, "/download-counter-indicadores", {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return signedURL.toString()
}

export { getIndicadores, getDownloadURL }
