import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { HEIGHT } from "../Layout/Layout"

interface IStyleProps {
  viewPortHeight?: boolean
}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {}
  },

  helpText: {
    margin: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.lightText
  },

  controllerContainer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 0),
      height: ({ viewPortHeight }: IStyleProps) =>
        viewPortHeight ? `calc(100vh - ${ HEIGHT * 2 }px)` : "100%"
    }
  },

  itemNavigatorContainer: {
    padding: theme.spacing(2, 0),
    borderRadius: theme.roundness,
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
      padding: theme.spacing(0)
    }
  },

  darkBg: {
    backgroundColor: theme.palette.primary.main
  },

  itemNavigatorTextContainer: {
    padding: theme.spacing(0.5),
    writingMode: "vertical-rl",
    transform: "scale(-1, -1)",
    color: theme.palette.text.lightText,
    "& span": {
      fontSize: theme.typography.body2.fontSize,
      margin: theme.spacing(1, 0),
      "& strong": {
        fontSize: theme.typography.body1.fontSize
      }
    }
  },
  itemsStyle: {
    justifyContent: "flex-start",
    maxHeight: "100%",
    overflow: "scroll",
    padding: theme.spacing(0),
    listStyleType: "none",

    "& ul.category-list": {
      padding: theme.spacing(0),
      listStyleType: "none",
      width: "100%"
    }
  },

  itemCategory: {
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(1),

    "& h6": {
      width: "100%",
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
      textTransform: "uppercase"
    },

    "& ul.item-list": {
      padding: theme.spacing(1),
      listStyleType: "none"
    },

    "& li": {
      padding: theme.spacing(1, 0)
    }
  },
  item: {
    width: "100%",
    cursor: "pointer",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: theme.spacing(0.5, 0),

    "& #map-nav-active": {
      padding: theme.spacing(0.5, 0),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText
    },
    "& span": {
      transition: theme.palette.extras.transitions.default,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.lightMild,
      letterSpacing: theme.spacing(0.05),
      // textDecoration: "underline",

      "&:hover": {
        color: theme.palette.text.lightMild
      }
    }
  }
}))
