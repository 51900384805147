import { Grid, Typography } from "@material-ui/core"
import * as React from "react"

import useStyles from "./styles"

interface ISectionText {
  text: string
  regularBottom?: boolean
  light?: boolean
}

const SectionText: React.FC<ISectionText> = ({
                                               text,
                                               regularBottom,
                                               light
                                             }) => {
  const { root, description } = useStyles({ regularBottom, light })
  return (
    <Grid container className={ root }>
      <Typography component="p" className={ description }>
        { text }
      </Typography>
    </Grid>
  )
}

export default SectionText
