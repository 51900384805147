import { Grid } from "@material-ui/core"
import * as React from "react"
import { ReactNode } from "react"

import useStyles from "./styles"

interface IPageNavigatorLayout {
  navigatorComponent: ReactNode
  detailComponent: ReactNode
}

const PageNavigatorLayout: React.FC<IPageNavigatorLayout> = ({
                                                               navigatorComponent,
                                                               detailComponent
                                                             }) => {
  const styles = useStyles()

  return (
    <Grid container item className={ styles.controllerContainer }>
      <Grid
        container
        item
        md={ 3 }
        className={ `${ styles.itemNavigatorContainer } ${ styles.darkBg }` }
      >
        { navigatorComponent }
      </Grid>

      <Grid container item md={ 9 } className={ styles.itemNavigatorContainer }>
        { detailComponent }
      </Grid>
    </Grid>
  )
}

export default PageNavigatorLayout
