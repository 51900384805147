import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { MAX_WIDTH } from "../Layout/Layout"

interface IStyleProps {
  isWindowOnTop: boolean
  isOpen: boolean
  HEIGHT: number
}

export default makeStyles((theme: ITheme) => ({
  appBar: {
    boxShadow: "none",
    background: theme.palette.background.paper,
  },

  backdrop: {
    backgroundColor: "transparent",
    backdropFilter: "blur(3px)",
  },

  navbar: {
    position: "relative",
    height: ({ HEIGHT }: IStyleProps) => HEIGHT,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: MAX_WIDTH,
    },
  },

  navbarMask: {
    width: "100%",
    height: "100%",

    position: "absolute",
    top: 0,
    left: 0,

    background: ({ isWindowOnTop, isOpen }: IStyleProps) =>
      isWindowOnTop || isOpen
        ? theme.palette.extras.none
        : "rgba(255, 255, 255, 0.90)",

    backdropFilter: "blur(3px)",
    opacity: ({ isWindowOnTop }) => (isWindowOnTop ? 0 : 1),

    transition: theme.palette.extras.transitions.default,
  },

  navLinks: {
    margin: theme.spacing(0),
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),

    width: "100%",

    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,

    listStyleType: "none",
    transition: theme.palette.extras.transitions.default,
    overflow: "hidden",
    backgroundColor: "rgba(255, 255, 255, 0.90)",
    backdropFilter: "blur(3px)",

    borderBottomLeftRadius: theme.roundness,
    borderBottomRightRadius: theme.roundness,
    boxShadow: theme.shadows[6],
    "& a": {
      display: "block",
      padding: theme.spacing(1),
      margin: theme.spacing(1.5, 0),
      textDecoration: "none",
      textTransform: "none",
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled,
      transition: theme.palette.extras.transitions.default,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: theme.letterspacing(1),
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      height: "auto",
      position: "static",
      justifyContent: "space-around",
      padding: theme.spacing(0, 0),
      margin: theme.spacing(0),
      borderRadius: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
      "& a": {
        fontSize: theme.typography.body2.fontSize,
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "center",
        "& :hover": {
          color: theme.palette.secondary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  },

  animWrapper: {
    [theme.breakpoints.up("md")]: {
      opacity: "1 !important",
      height: ({ HEIGHT }: IStyleProps) => HEIGHT,
    },
  },

  selectedNavLink: {
    "& a": {
      width: "100%",

      color: theme.palette.text.lightText,
      fontWeight: theme.typography.fontWeightBold,

      backgroundColor: theme.palette.secondary.main,

      borderRadius: theme.roundness,
    },
    "& :hover": {
      color: theme.palette.text.lightText,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  logoBtnContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    opacity: ({ isWindowOnTop }) => isWindowOnTop && 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoBtn: {
    display: "block",
    width: 50,
    height: 50,
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    zIndex: 9000,
  },

  menuContainer: {
    opacity: ({ isWindowOnTop }) => isWindowOnTop && 0,
    transition: theme.palette.extras.transitions.default,
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  hidden: {
    transition: theme.palette.extras.transitions.default,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  shown: {
    transition: theme.palette.extras.transitions.default,
    padding: theme.spacing(1),
    "& a": {
      display: "block",
      width: "70%",
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
      [theme.breakpoints.up("md")]: {
        width: "70%",
      },
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}))
