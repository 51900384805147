import { useState, useCallback } from 'react';

type HookReturnType = [
  isButtonLoading: boolean,
  setButtonLoader: (bool: boolean) => void
];

export default (): HookReturnType => {
  const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

  const setButtonLoader = useCallback((bool: boolean) => {
    setButtonLoading(bool);
    return;
  }, []);

  return [isButtonLoading, setButtonLoader];
};
