import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  color: string
}

export default makeStyles((theme: ITheme) => ({
  sdRoot: {
    alignItems: "center",
    marginBottom: theme.spacing(6),
  },

  sdTitleContainer: {
    flexGrow: 0,
    flexShrink: 1,
  },

  sdTitle: {
    paddingRight: theme.spacing(1),
    color: ({ color }: IStyleProps) =>
      color ? color : theme.palette.text.primary,
    textTransform: "uppercase",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.letterspacing(0.75),
  },

  sdDivider: {
    width: "100%",
  },
}))
