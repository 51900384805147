import createDataContext from "../createDataContext"
import * as Helpers from "./methods/helpers"
import * as Main from "./methods/main"
import * as Opendata from "./methods/opendata"

import * as OpendataHistory from "./methods/opendataHistory"
import entriesReducer, { ReducerType } from "./reducer"
import { Actions, StateElems } from "./types"

export * from "./types"

export const API_NAME = "pnifamplifyExpress"

export const { Provider, Context } = createDataContext<StateElems,
  Actions,
  ReducerType>(
  entriesReducer,
  {
    // Main
    getDownloadURL: Main.getDownloadURL,
    getAllVariables: Main.getAllVariables,
    getDescripciones: Main.getDescripciones,

    // Opendata
    getOpendata: Opendata.getOpendata,
    uploadOpendataFile: Opendata.uploadOpendataFile,
    getQueryVariable: Opendata.getQueryVariable,
    getOpendataFull: Opendata.getOpendataFull,
    getOpendataMeta: Opendata.getOpendataMeta,

    // Opendata History
    getOpendataHistory: OpendataHistory.getOpendataHistory,
    getOpendataHistoryPeriodos: OpendataHistory.getOpendataHistoryPeriodos,

    // Helpers
    setLoadingDatosAbiertos: Helpers.setLoadingDatosAbiertos,
    resetDatosAbiertos: Helpers.resetDatosAbiertos,
    resetVariables: Helpers.resetVariables
  },
  {
    datosAbiertos: [],
    metadata: null,
    datosAbiertosLastFetch: null,
    variables: null,
    historyVariables: null,
    descripciones: null,
    periodos: null,
    sectores: null,
    categorias: null,
    pagina: 1,
    numPag: 1,
    numReg: 0,
    registrosPorPagina: 5,
    datosAbiertosParams: null,
    loadingDatosAbiertos: false,

    errorMessage: "",
    successMessage: ""
  }
)
