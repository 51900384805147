import { useEffect, useState } from 'react'
import { globalHistory } from '@reach/router'

type IHookReturnType = [path: string]

export default (): IHookReturnType => {
  const [path, setPath] = useState<string>(globalHistory.location.pathname)

  useEffect(() => {
    setPath(globalHistory.location.pathname)
  }, [])

  return [path]
}
