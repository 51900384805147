import { useEffect } from 'react';
import { useState } from 'react';

import {
  isSafari,
  isChrome,
  isFirefox,
  browserVersion,
} from 'react-device-detect';

const options = {
  safari: {
    browserAllowedVersion: 10,
    errMsg: 'Actualiza la versión de tu navegador Safari.',
  },
  chrome: {
    browserAllowedVersion: 83,
    errMsg: 'Actualiza la versión de tu navegador Chrome.',
  },
  firefox: {
    browserAllowedVersion: 77,
    errMsg: 'Actualiza la versión de tu navegador Firefox.',
  },
};

const useBrowserDetection = () => {
  const [browserError, setBrowserError] = useState(null);

  useEffect(() => {
    // Revisa si es un navegador no permitido y no permite continuar
    if (!isSafari && !isChrome && !isFirefox) {
      return setBrowserError('Para visualizar mejor las funciones de la plataforma recomendamos el uso de los' +
        ' siguientes navegadores: Firefox, Chrome o Safari.');
    }

    // Revisa si es Edge y tiene la última versión
    if (isSafari) {
      if (parseInt(browserVersion) < options.safari.browserAllowedVersion) {
        return setBrowserError(options.safari.errMsg);
      }
    }

    if (isChrome) {
      if (parseInt(browserVersion) < options.chrome.browserAllowedVersion) {
        return setBrowserError(options.chrome.errMsg);
      }
    }

    if (isFirefox) {
      if (parseInt(browserVersion) < options.firefox.browserAllowedVersion) {
        return setBrowserError(options.firefox.errMsg);
      }
    }

    return setBrowserError(null);
  }, []);

  return { browserError };
};

export default useBrowserDetection;
