import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHooksHelpers } from "."
import {
  Actions,
  Context as DAContext,
  IDatosAbiertosContextType,
  IVarParams,
  StateElems
} from "../context/DatosAbiertosContext"
import { IHookHelpersHook, ISearchParams } from "./useHooksHelpers"

type TDispatchArrayStrings<T> = React.Dispatch<React.SetStateAction<T>>

interface IPageOptions {
  pagina: number
  cantidadRegistros: number
}

export interface IOpendataHook
  extends Partial<StateElems>,
    Partial<Actions>,
    Partial<IHookHelpersHook> {
  tipo: string
  orden: boolean
  categoriaIndex: number
  elementsPP: number
  sectorIndex: number
  descripcionIndex: number
  ordenarPor: string
  loadingVariable: boolean
  isSearchAvailable: boolean
  fetchOpendata: (pageOptions?: IPageOptions) => Promise<void>
  fetchOpendataFull: (pageOptions?: IPageOptions) => Promise<void>
  setPage: TDispatchArrayStrings<number>
  setElementsPP: TDispatchArrayStrings<number>
  setCategoriaIndex: TDispatchArrayStrings<number>
  setSectorIndex: TDispatchArrayStrings<number>
  setDescripcionIndex: TDispatchArrayStrings<number>
  setTipo: TDispatchArrayStrings<string>
  setOrden: TDispatchArrayStrings<boolean>
  setOrdenarPor: TDispatchArrayStrings<string>
  handleSortByColumnIndex: (i: number) => void
  resetFilters: () => void
}

interface IHookOptions {
  noFetchOnLoad?: boolean
  searchParamsList?: ISearchParams[]
}

export default (options?: IHookOptions): IOpendataHook => {
  const {
    state: {
      datosAbiertos,
      loadingDatosAbiertos,
      variables,
      categorias,
      sectores,
      descripciones,
      pagina,
      numPag,
      numReg,
      registrosPorPagina,
      metadata
    },
    getOpendata,
    getOpendataMeta,
    getQueryVariable,
    getAllVariables,
    getDescripciones,
    getOpendataFull,
    getDownloadURL,
    resetDatosAbiertos,
    resetVariables
  } = useContext<IDatosAbiertosContextType>(DAContext)

  const [page, setPage] = useState<number>(null)
  const [elementsPP, setElementsPP] = useState<number>(null)

  const [categoriaIndex, setCategoriaIndex] = useState(null)
  const [sectorIndex, setSectorIndex] = useState(null)
  const [descripcionIndex, setDescripcionIndex] = useState(null)
  const [tipo, setTipo] = useState(null)
  const [ordenarPor, setOrdenarPor] = useState("_id")
  const [orden, setOrden] = useState<boolean>(null)

  const [conEstado, setConEstado] = useState(false)
  const [variableBuscada, setVariableBuscada] = useState("")

  const [isSearchAvailable, setSearchAvailable] = useState(false)

  const searchParamsUpdated = useMemo(() => {
    return [
      ...(conEstado
        ? [
          { id: 0, field: "Nivel geográfico", colProp: "_id" },
          { id: 1, field: "Estado", colProp: "estado" }
        ]
        : [{ id: 0, field: "Nivel geográfico", colProp: "_id" }]),

      { id: 2, field: variableBuscada, colProp: "variable" },
      ...options.searchParamsList
    ]
  }, [conEstado, variableBuscada])

  const { paginator, listTitles, colProps, chips } = useHooksHelpers({
    pagina,
    numPag,
    searchParamsList: searchParamsUpdated
  })

  const sectorSeleccionado = useMemo(() => {
    if (sectores) {
      return sectores[sectorIndex]
    }
  }, [sectorIndex, sectores])

  const categoriaSeleccionada = useMemo(() => {
    if (categorias) {
      return categorias[categoriaIndex]
    }
  }, [categoriaIndex, categorias])

  const descripcionSeleccionada = useMemo(() => {
    if (descripciones) {
      return descripciones[descripcionIndex]
    }
  }, [descripcionIndex, descripciones])

  const [loadingVariable, setLoadingVariable] = useState(false)

  const fetchOpendata = useCallback(
    async ({ pagina = page || 1 }) => {
      if (isSearchAvailable) {
        const variable = await getQueryVariable(
          {
            categoria: categoriaSeleccionada,
            sector: sectorSeleccionado,
            descripcion: descripcionSeleccionada
          },
          "opendata"
        )

        // Se utiliza para desplegar (o no) la columna estado en la tabla
        if (tipo === "municipios") {
          setConEstado(true)
        } else {
          setConEstado(false)
        }

        // Se utiliza para el título de la variable en la tabla
        setVariableBuscada(descripcionSeleccionada)

        await getOpendata({
          tipo,
          variable,
          ordenarPor,
          orden: orden ? -1 : 1, // 1 ASC -1 DESC
          pagina,
          elementosPagina: elementsPP
        })
      }
    },
    [
      categoriaSeleccionada,
      sectorSeleccionado,
      descripcionSeleccionada,
      tipo,
      ordenarPor,
      orden,
      elementsPP
    ]
  )

  const fetchOpendataFull = useCallback(async () => {
    if (isSearchAvailable) {
      const varParams: IVarParams = {
        categoria: categoriaSeleccionada,
        sector: sectorSeleccionado,
        descripcion: descripcionSeleccionada
      }
      const variable = await getQueryVariable(varParams, "opendata")
      await getOpendataFull(
        {
          tipo,
          variable,
          ordenarPor,
          orden: orden ? -1 : 1, // 1 ASC -1 DESC
          pagina: 1,
          elementosPagina: 1
        },
        varParams,
      )
    }
  }, [
    categoriaSeleccionada,
    sectorSeleccionado,
    descripcionSeleccionada,
    tipo,
    ordenarPor,
    orden,
    elementsPP
  ])

  const handleSortByColumnIndex = useCallback(
    i => {
      let searchParam: ISearchParams

      if (i === 0) {
        searchParam = searchParamsUpdated[0]
      } else {
        searchParam = searchParamsUpdated.find(e =>
          conEstado ? e.id === i : e.id === i + 1
        )
      }

      setOrden(prev => !prev)
      setOrdenarPor(searchParam.colProp)
    },
    [searchParamsUpdated, conEstado]
  )

  const resetFilters = useCallback(() => {
    setCategoriaIndex(null)
    setSectorIndex(null)
    setDescripcionIndex(null)
    setTipo(null)
    setOrdenarPor("variable")
    setOrden(null)
    setConEstado(false)
    setPage(1)
    setElementsPP(10)
    resetDatosAbiertos()
    resetVariables()
  }, [])

  useEffect(() => {
    if (!variables) {
      setLoadingVariable(true)
      getAllVariables("opendata")
    } else {
      setLoadingVariable(false)
    }
  }, [variables])

  useEffect(() => {
    if (page !== null) {
      fetchOpendata({ pagina: page })
    }
  }, [page])

  useEffect(() => {
    fetchOpendata({ cantidadRegistros: elementsPP, pagina: 1 })
  }, [orden])

  useEffect(() => {
    if (categoriaSeleccionada && sectorSeleccionado) {
      setDescripcionIndex(0)
      getDescripciones(categoriaSeleccionada, sectorSeleccionado, "opendata")
    }
  }, [categoriaSeleccionada, sectorSeleccionado])

  useEffect(() => {
    if (
      Boolean(
        categoriaIndex !== null &&
        sectorIndex !== null &&
        descripcionIndex !== null &&
        tipo !== null &&
        ordenarPor
      )
    ) {
      setSearchAvailable(true)
    } else {
      setSearchAvailable(false)
    }
  }, [categoriaIndex, sectorIndex, descripcionIndex, tipo, orden])

  useEffect(() => {
    resetFilters()
    return () => resetFilters()
  }, [])

  return {
    datosAbiertos,
    loadingDatosAbiertos,
    fetchOpendata,
    fetchOpendataFull,
    variables,
    sectores,
    sectorIndex,
    categorias,
    categoriaIndex,
    descripciones,
    descripcionIndex,
    tipo,
    orden,
    setOrden,
    elementsPP,
    ordenarPor,
    loadingVariable,
    isSearchAvailable,
    metadata,
    getOpendataMeta,
    getDownloadURL,

    // Setters
    setPage,
    setElementsPP,
    setCategoriaIndex,
    setSectorIndex,
    setDescripcionIndex,
    setTipo,
    setOrdenarPor,
    resetFilters,

    // API Pagination
    pagina,
    numPag,
    numReg,
    registrosPorPagina,
    paginator,

    // Data table
    listTitles,
    colProps,
    chips,
    handleSortByColumnIndex
  }
}
