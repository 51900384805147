import * as React from "react"
import { Dispatch, ReactNode, SetStateAction } from "react"
import { ActivityLoader } from "../../_common"
import PageNavigatorLayout from "../PageNavigatorLayout/PageNavigatorLayout"
import Navigator from "./Navigator"

import useStyles from "./styles"

export interface IDataCategory<T> {
  id: string
  title: string
  data: T[]
}

interface IPageNavigator<T> {
  children: ReactNode
  title: string
  titleStrong: string
  data: IDataCategory<T>[]
  loading: boolean
  instructions: string
  loadingText: string
  currentSelection: T
  setCurrentSelection: Dispatch<SetStateAction<T>>
  setCurrentCategory: Dispatch<SetStateAction<IDataCategory<T>>>
  mobileAction: (() => void) | (() => Promise<void>)
  scrollOffset?: number
  viewPortHeight?: boolean
  footer?: ReactNode
}

const PageNavigator = <T extends { id: string }>({
                                                   children,
                                                   loading,
                                                   loadingText,
                                                   data,
                                                   title,
                                                   titleStrong,
                                                   currentSelection,
                                                   setCurrentSelection,
                                                   setCurrentCategory,
                                                   scrollOffset,
                                                   mobileAction,
                                                   viewPortHeight,
                                                   footer,
                                                   instructions
                                                 }: IPageNavigator<T>) => {
  const styles = useStyles({ viewPortHeight })

  return (
    <PageNavigatorLayout
      detailComponent={
        <>
          { loading ? (
            <ActivityLoader text={ loadingText } />
          ) : (
            children
          ) }
        </>
      }
      navigatorComponent={
        <Navigator
          { ...{
            title,
            titleStrong,
            setCurrentCategory,
            currentSelection,
            setCurrentSelection,
            data,
            loading,
            loadingText,
            scrollOffset,
            mobileAction,
            viewPortHeight,
            footer,
            instructions
          } }
        />
      }
    />
  )
}

export default PageNavigator
