import { Grid } from "@material-ui/core"
import * as React from "react"
import { ReactNode } from "react"
import { HEIGHT } from "../../_layout/Layout/Layout"

import useStyles from "./styles"

interface ITopbar {
  children: ReactNode
}

const Topbar: React.FC<ITopbar> = ({ children }) => {
  const styles = useStyles({ HEIGHT })

  return (
    <Grid container className={ styles.topbar }>
      { children }
    </Grid>
  )
}

export default Topbar
