import { API } from "aws-amplify"
import { API_NAME } from ".."
import { ExtractSearchVariableQuery, ExtractSearchVariableQueryVariables } from "../../../API"
import { extractSearchVariable } from "../../../graphql/queries"
import { downloadCSV } from "../../../helpers/helperFunctions"
import { IMetaData } from "../../../helpers/Types"
import { callGraphQL, genericCrud } from "../../contextHelpers"
import {
  Dispatch,
  FileCB,
  IMetadataDatosAbiertos,
  IOpendataQueryParams,
  IQueryVariable,
  IVarParams,
  VariableType
} from "../types"
import { mapVars, saveS3File, setLoadingDatosAbiertos } from "./helpers"
import VariableFactory from "../../../lib/VariableFactory"


const getOpendata = (dispatch: Dispatch) => async (
  params: IOpendataQueryParams
) => {
  const successCallback = async (data: any) => {
    const datosAbiertos = data[0].data
    const { page, total_reg, page_size }: IMetaData = data[0].metadata[0]

    dispatch({
      type: "set_datos_abiertos",
      payload: {
        datosAbiertos,
        datosAbiertosLastFetch: new Date().getTime(),
        pagina: page,
        numPag: Math.ceil(total_reg ? total_reg / page_size : 1),
        numReg: total_reg,
        registrosPorPagina: page_size
      }
    })
  }

  await setLoadingDatosAbiertos(dispatch)(true)
  await genericCrud(
    dispatch,
    API.get(API_NAME, "/opendata", {
      headers: {
        "Content-Type": "application/json"
      },
      queryStringParameters: params
    }),
    successCallback
  )
  await setLoadingDatosAbiertos(dispatch)(false)
}

const getOpendataFull = (dispatch: Dispatch) => async (
  params: IOpendataQueryParams,
  varParams: IVarParams
) => {
  const title = `${ varParams.categoria.toLowerCase() }-${ varParams.sector.toLowerCase() }-${ varParams.descripcion.toLowerCase() }`
  const successCallback = async (data: any) => {
    downloadCSV(data, title)

    // typeof window !== "undefined" && window.open(blobURL)
  }

  await genericCrud(
    dispatch,
    API.get(API_NAME, "/opendata-nopag", {
      headers: {
        "Content-Type": "application/json"
      },
      queryStringParameters: params
    }),
    successCallback
  )
}

const getOpendataMeta = (dispatch: Dispatch) => async (
  referencia: VariableType
) => {
  const getURLByReference = (referencia: VariableType) => {
    const urls = {
      opendata: "/opendata-metadata",
      historico: "/opendata-history-metadata",
      enif: "/enif-metadata"
    }

    return urls[referencia] ?? ""
  }

  const successCallback = async (data: IMetadataDatosAbiertos) => {
    dispatch({
      type: "set_meta",
      payload: {
        metadata: data
      }
    })
  }

  await setLoadingDatosAbiertos(dispatch)(true)
  await genericCrud(
    dispatch,
    API.get(API_NAME, getURLByReference(referencia), {
      headers: {
        "Content-Type": "application/json"
      }
    }),
    successCallback
  )
  await setLoadingDatosAbiertos(dispatch)(false)
}

const getQueryVariable = (dispatch: Dispatch) => async (
  { sector, categoria, descripcion }: IQueryVariable,
  referencia: VariableType
) => {
  const variableQueryResult = await callGraphQL<ExtractSearchVariableQuery,
    ExtractSearchVariableQueryVariables>(extractSearchVariable, {
    variables: {
      categoria,
      sectorDescripcion: { beginsWith: { sector, descripcion } }
    }
  })

  const mappedVars = mapVars(variableQueryResult)

  if (mappedVars.length > 0) {
    const filteredVars = mappedVars.filter(e => e.referencia === referencia)
    const variable = filteredVars[0].variable
    const historyVariable = variable.split("h_")
    if (historyVariable.length === 1) {
      return historyVariable[0]
    } else if (historyVariable.length === 2) {
      return historyVariable[1]
    }
  }

  return null
}

const uploadOpendataFile = (dispatch: Dispatch) => async (
  referencia: VariableType,
  file: File,
  cb: FileCB
) => {
  try {
    // Saves file on bucket
    await saveS3File(file, cb)

    // Uploads variable
    const Variables = VariableFactory(file, referencia)
    Variables.uploadVariables()

  } catch
    (err) {
    console.log("Error uploading file: ", err)
  }
}


// TODO guardar variables de la ENIF: cat_pregunta y cat_categoria
export {
  getOpendata,
  uploadOpendataFile,
  getQueryVariable,
  getOpendataFull,
  getOpendataMeta
}
