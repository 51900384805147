import { Object } from "../helpers/Types"
import { GraphQLResult } from "@aws-amplify/api"
import { API, graphqlOperation } from "aws-amplify"

// Receives Context ReducerType and Context StateElems as TS arguments
export type DispatchActionType<T, K> = (action: ReducerAction<T, K>) => void
export type AsyncCallback<T> = (...args: any[]) => Promise<T>
export type ActionsCallback = (...args: any) => void

export interface ContextMessages {
  errorMessage: string
  successMessage: string
}

export interface ReducerAction<T, P> {
  type: T
  payload?: Partial<P>
}

export interface GenericBackendResponse {
  success: boolean
  msg: string
  data: Object
}

export interface GraphQLOptions<T> {
  input?: object
  variables?: T
  authMode?: any
}

export const genericCrud = async <
  T,
  K extends DispatchActionType<"add_error", { errorMessage: string }>
>(
  dispatch: K,
  apiCall: Promise<GenericBackendResponse | any>,
  successCallback: (data: T) => Promise<void>,
  errorCallback?: () => void
) => {
  const res = await apiCall
  const { success, msg, data } = res.data

  if (success) {
    await successCallback(data)
    return data
  } else {
    if (errorCallback) {
      return errorCallback()
    }
    dispatch({ type: "add_error", payload: { errorMessage: msg } })
    return null
  }
}

export async function callGraphQL<T, K>(
  query: any,
  options?: GraphQLOptions<K>
): Promise<GraphQLResult<T>> {
  return (await API.graphql(
    graphqlOperation(query, options?.variables)
  )) as GraphQLResult<T>
}
