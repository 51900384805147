import { ChangeEvent, ReactNode } from "react"
import * as React from "react"

import {
  Accordion as MUIAccordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid
} from "@material-ui/core"

import { MdExpandMore } from "react-icons/md"
import useStyles from "./styles"

interface IAccordionProps {
  title: string
  content: ReactNode
  disabled?: boolean
  light?: boolean
  expanded?: boolean
  onChange?: (e: ChangeEvent<{}>) => void
}

const Accordion: React.FC<IAccordionProps> = ({
                                                content,
                                                title,
                                                disabled,
                                                expanded,
                                                onChange,
  light
                                              }) => {
  const styles = useStyles({light})
  return (
    <MUIAccordion
      classes={ { root: styles.root } }
      { ...{ disabled, expanded, onChange } }
    >
      <AccordionSummary
        expandIcon={ <MdExpandMore color={light ? 'darkGrey' : 'white'} /> }
        aria-controls="controles de busqueda"
      >
        <Grid item container className={ styles.heading }>
          <Typography component="h6">{ title }</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{ content }</AccordionDetails>
    </MUIAccordion>
  )
}

export default Accordion
