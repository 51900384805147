import { Typography } from "@material-ui/core/"
import * as React from "react"
import useStyles from "./styles"

interface IFormErrorMessage {
  errorMessage: string
}

const FormErrorMessage: React.FC<IFormErrorMessage> = ({ errorMessage }) => {
  const { error, errorRoot } = useStyles()
  return (
    <div className={ errorRoot }>
      { errorMessage && (
        <Typography variant="body2" className={ error }>
          { errorMessage }
        </Typography>
      ) }
    </div>
  )
}

export default FormErrorMessage
