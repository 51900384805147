import * as React from "react"
import Loader from "react-loader-spinner"

import { makeStyles } from "@material-ui/core"
import theme from "../../../App.theme"


const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))

const LoaderComponent = ({ light }) => {
  const { root } = useStyles()

  return <div className={ root }>
    {/*
 // @ts-ignore */ }
    <Loader
      type="RevolvingDot"
      color={
        light ? theme.palette.text.lightText : theme.palette.primary.main
      }
      height={ 100 }
      width={ 100 }
    />
  </div>

}

export default LoaderComponent
