import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Logo } from ".."
import { Grid, Typography } from "@material-ui/core"

import useStyles from "./styles"
import { HEIGHT } from "../../_layout/Layout/Layout"

const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo512.png" }) {
      childImageSharp {
        fluid(maxWidth: 850) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    secondaryLogo: file(relativePath: { eq: "secondaryLogo512.png" }) {
      childImageSharp {
        fluid(maxWidth: 850) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Logos = () => {
  const { logo, secondaryLogo } = useStaticQuery(getLogo)
  const styles = useStyles({ HEIGHT })

  return (
    <Grid item container className={styles.logosContainer}>
      <Grid item xs md={2} className={styles.imgContainer}>
        <AniLink to="/" fade>
          <Logo image={logo} alt="logo-pnif" />
        </AniLink>
      </Grid>

      <Grid container item xs className={styles.titleContainer}>
        <Typography className={styles.subtitle}>
          Plataforma de seguimiento y monitoreo
        </Typography>
        <Typography className={styles.title}>
          Política nacional de inclusión financiera
        </Typography>
      </Grid>

      <Grid item xs md={2} className={styles.imgContainer}>
        <AniLink to="/" fade>
          <Logo image={secondaryLogo} alt="comite-de-educacion-financiera" />
        </AniLink>
      </Grid>
    </Grid>
  )
}

export default Logos
