import { AppBar, Backdrop, Grid, IconButton, Slide, useScrollTrigger } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as React from "react"
import { FC, Fragment, ReactElement, ReactNode, useEffect, useState } from "react"
import { GrClose } from "react-icons/gr"
import { RiMenuLine } from "react-icons/ri"
import { animated, useSpring } from "react-spring"

import { navbarLinks } from "../../../data/navlinks"
import { usePath } from "../../../hooks"
import { Logo, Logos, Topbar } from "../../_common"
import { HEIGHT } from "../Layout/Layout"
import useStyles from "./styles"

interface IHideOnScroll extends INavbar {
  children: ReactElement<any, any>
  topbarComponent?: ReactNode
  window?: () => Node
}

interface INavbar {
  withNav: boolean
}

const getLogo = graphql`
    {
        logo: file(relativePath: { eq: "logo512.png" }) {
            childImageSharp {
                fluid(maxWidth: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const HideOnScroll: FC<IHideOnScroll> = props => {
  const { children, window } = props

  const trigger = useScrollTrigger({
    target: typeof window !== "undefined" ? window() : undefined
  })
  return (
    <Fragment>
      { props.withNav ? (
        <Fragment>{ children }</Fragment>
      ) : (
        <Slide appear={ false } direction="down" in={ !trigger }>
          { children }
        </Slide>
      ) }
    </Fragment>
  )
}

const Navbar: FC<INavbar> = props => {
  const [isWindowOnTop, setIsWindowOnTop] = useState(!props.withNav)
  const [isOpen, setNav] = useState(false)
  const styles = useStyles({ isWindowOnTop, isOpen, HEIGHT })

  const { logo } = useStaticQuery(getLogo)

  const [path] = usePath()

  const [selectedPageIndex, setSelectedPageIndex] = useState(
    navbarLinks.findIndex(e => e.path === path)
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        if (window.pageYOffset <= 200 && !props.withNav) {
          setIsWindowOnTop(true)
        } else {
          setIsWindowOnTop(false)
        }
      }
    }
    return () => {
      setIsWindowOnTop(false)
    }
  }, [props.withNav])

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "scroll"
    }
  }, [isOpen])

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const fadeOutProps = useSpring({
    opacity: isOpen ? 1 : 0
  })
  const fadeInProps = useSpring({ opacity: isOpen ? 0 : 1 })

  return (
    <Fragment>
      <HideOnScroll { ...props }>
        <AppBar classes={ { root: styles.appBar } }>
          <Grid className={ styles.hidden }>
            <Topbar>
              <Logos />
            </Topbar>
          </Grid>
          <Grid container className={ styles.navbar }>
            <Grid container item xs className={ styles.menuContainer }>
              <Grid container item xs justify="flex-end">
                { !isOpen && (
                  <Grid container item xs className={ styles.shown }>
                    <AniLink to="/" fade>
                      <Logo image={ logo } alt="logo-pnif" />
                    </AniLink>
                  </Grid>
                ) }
                <Grid container item xs className={ styles.logoBtnContainer }>
                  <IconButton
                    onClick={ toggleNav }
                    className={ styles.logoBtn }
                    aria-label="Menú"
                  >
                    { !isOpen ? (
                      <animated.div style={ fadeInProps }>
                        <RiMenuLine />
                      </animated.div>
                    ) : (
                      <animated.div style={ fadeOutProps }>
                        <GrClose />
                      </animated.div>
                    ) }
                  </IconButton>
                </Grid>
              </Grid>

              <animated.div
                style={ fadeOutProps }
                className={
                  isOpen
                    ? styles.animWrapper
                    : `${ styles.animWrapper } ${ styles.hidden }`
                }
              >
                <ul
                  className={
                    isOpen
                      ? styles.navLinks
                      : `${ styles.navLinks } ${ styles.hidden }`
                  }
                >
                  { navbarLinks.map((e, i) => (
                    <li
                      key={ i }
                      className={ path === e.path ? styles.selectedNavLink : "" }
                    >
                      <AniLink
                        fade
                        to={ e.path }
                        duration={ 2 }
                        onClick={ toggleNav }
                      >
                        { e.text }
                      </AniLink>
                    </li>
                  )) }
                </ul>
              </animated.div>
              <Backdrop
                className={ styles.backdrop }
                open={ isOpen }
                onClick={ toggleNav }
              />
            </Grid>
          </Grid>
        </AppBar>
      </HideOnScroll>
    </Fragment>
  )
}

export default React.memo(Navbar)
