import { FormSchemaTextObject, FormSchemaValidation } from "../../../helpers/Types"


interface INewsletterFormType<T> {
  text: T
}

interface Schema {
  initialState: INewsletterFormType<FormSchemaTextObject>
  validationStateSchema: INewsletterFormType<FormSchemaValidation>
}

export default (): Schema => ({
  initialState: {
    text: { value: "", error: "" }
  },
  validationStateSchema: {
    text: {
      required: false
    }
  }
})
