import { ReactNode } from 'react'
import { Dispatch } from '../types'

const openModal = (dispatch: Dispatch) => async (
  title: string,
  component: ReactNode
) => {
  dispatch({ type: 'set_children', payload: { component, title } })
  dispatch({ type: 'open' })
}

const closeModal = (dispatch: Dispatch) => async () => {
  dispatch({ type: 'close' })
}

export { openModal, closeModal }
