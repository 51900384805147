import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { HEIGHT } from "./Layout"

interface IStyleProps {
  withNav: boolean
  HEIGHT: number
}

export default makeStyles((theme: ITheme) => ({
  main: {
    position: "relative",
    marginTop: ({ withNav, HEIGHT }: IStyleProps) => (withNav ? HEIGHT : 0),

    [theme.breakpoints.up("md")]: {
      marginTop: ({ withNav, HEIGHT }: IStyleProps) =>
        withNav ? HEIGHT * 2 : 0,
    },
  },

  button: {
    padding: theme.spacing(0.12),
    backgroundColor: "#F0BE74",
    fontSize: theme.typography.body2.fontSize,
    textTransform: "none",
    position: "fixed",
    top: HEIGHT + 10,
    left: "50%",

    width: "7rem",
    marginLeft: "-3.5rem",

    zIndex: 9000,
    opacity: 1,

    "& span": {
      color: "black",
      fontWeight: "bold",
      fontSize: theme.typography.body2.fontSize,
    },

    "&:hover": {
      backgroundColor: "#F0BE74",
    },

    [theme.breakpoints.up("md")]: {
      top: HEIGHT * 2 + 10,
    },
  },
}))
