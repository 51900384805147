import { API } from "aws-amplify"
import {
  ExtractSearchVariableEnifQuery,
  ExtractSearchVariableEnifQueryVariables,
  ListVariableCategoriaEnifsQuery,
  ListVariableCategoriaEnifsQueryVariables,
  ListVariableEnifsQuery,
  ListVariableEnifsQueryVariables
} from "../API"
import { callGraphQL, GraphQLOptions } from "../context/contextHelpers"
import { API_NAME } from "../context/DatosAbiertosContext"
import { extractSearchVariableEnif, listVariableCategoriaEnifs, listVariableEnifs } from "../graphql/queries"

export interface InitializeConfigurationParams {
  resultados: any[]
  variables: any[]
  secciones: string[]
  preguntas: string[]
  categorias: any[]
  ordenarPor: OrdenParam
  orden: Orden
}

export type Orden = 1 | -1

export type OrdenParam = "_id" | "desagregacion" | "variable" | "porcentaje_poblacion"

interface ISearchParams {
  categoria: string
  pregunta: string
  ordenarPor: OrdenParam
  orden: Orden
}

const ENIFControls = (function() {
  // private singleton value which gets initialized only once
  let config: InitializeConfigurationParams
  let initialConfig
  let seccionIndex = null
  let preguntaIndex = null
  let categoriaIndex = null

  function InitializeConfiguration(initialValues: InitializeConfigurationParams) {
    this.resultados = initialValues.resultados
    this.variables = initialValues.variables
    this.secciones = initialValues.secciones
    this.preguntas = initialValues.preguntas
    this.categorias = initialValues.categorias
    this.ordenarPor = initialValues.ordenarPor
    this.orden = initialValues.orden
  }

  const mapVariable = (varArr: any[], prop: string) => {
    return varArr.map(e => e[prop])
    .filter((v, i, a) => a.indexOf(v) === i)
  }

  const fetchClavePregunta = async () => {
    const seccion = handleSeccion()
    const pregunta = handlePregunta()

    const variableQueryResult = await callGraphQL<ExtractSearchVariableEnifQuery,
      ExtractSearchVariableEnifQueryVariables>(extractSearchVariableEnif, {
      variables: {
        seccion,
        pregunta: { eq: pregunta }
      }
    })
    return variableQueryResult.data.extractSearchVariableEnif.items[0]
  }


  const handleSeccion = () => {
    const secciones = config.secciones

    if (secciones) {
      return secciones[seccionIndex]
    }
    return null
  }

  const handlePregunta = () => {
    const preguntas = config.preguntas

    if (preguntas) {
      return preguntas[preguntaIndex]
    }
    return null
  }

  const handleCategoria = () => {
    const categorias = config.categorias

    if (categorias) {
      return categorias[categoriaIndex]
    }
    return null
  }

  return {
    initialize: (values: InitializeConfigurationParams) => {
      // we initialize the singleton value only once
      if (config === undefined) {
        const originalObj = new InitializeConfiguration(values)
        initialConfig = Object.create(originalObj)
        config = Object.create(originalObj)
      }

      return config
    },

    getState: () => {
      return config
    },

    setSeccionIndex: (seccionSeleccionada: number) => {
      seccionIndex = seccionSeleccionada
      const seccion = handleSeccion()

      if(config.variables) {
        const preguntas = config.variables.filter(e => e.seccion === seccion)
        config.preguntas = mapVariable(preguntas, 'pregunta')
      }
      return config.preguntas.sort()
    },
    setPreguntaIndex: (preguntaSeleccionada: number) => {
      preguntaIndex = preguntaSeleccionada
    },
    setCategoriaIndex: (categoriaSeleccionada: number) => {
      categoriaIndex = categoriaSeleccionada
    },
    setOrden: (orden: Orden) => {
      config.orden = orden
      return config.orden
    },
    setOrdenarPor: (ordenarPor: OrdenParam) => {
      config.ordenarPor = ordenarPor
      return config.ordenarPor
    },

    getSelectedParams: () => {
      return {
        seccion: handleSeccion(),
        pregunta: handlePregunta(),
        categoria: handleCategoria(),

        seccionIndex,
        preguntaIndex,
        categoriaIndex
      }
    },

    fetchVariables: async () => {

      let preguntasData
      try {
        preguntasData = await callGraphQL<ListVariableEnifsQuery,
          GraphQLOptions<ListVariableEnifsQueryVariables>>(listVariableEnifs, {
          variables: {
            // @ts-ignore
            limit: 500
          }
        })
      } catch (err) {
        console.log(err)
      }


      const categoriasData = await callGraphQL<ListVariableCategoriaEnifsQuery,
        GraphQLOptions<ListVariableCategoriaEnifsQueryVariables>>(listVariableCategoriaEnifs, {
        variables: {
          // @ts-ignore
          limit: 500
        }
      })

      config.categorias = categoriasData.data.listVariableCategoriaEnifs.items.sort((a,
                                                                                     b) => (a.categoria > b.categoria) ? 1 : -1)
      config.variables = preguntasData.data.listVariableEnifs.items
      config.secciones = mapVariable(config.variables, "seccion").sort()

      return {
        secciones: config.secciones,
        categorias: config.categorias
      }
    },

    reset: () => {
      config = {
        resultados: [],
        variables: [],
        secciones: [],
        preguntas: [],
        categorias: [],
        ordenarPor: '_id',
        orden: 1
      }
      seccionIndex = null
      preguntaIndex = null
      categoriaIndex = null
    },

    search: async () => {
      const { cve_pregunta } = await fetchClavePregunta()
      const { cve_cat } = handleCategoria()

      const queryParams: ISearchParams = {
        ordenarPor: config.ordenarPor,
        orden: config.orden,
        pregunta: cve_pregunta,
        categoria: cve_cat

      }

      const { data: { success, data } } = await API.get(API_NAME, "/enif", {
        headers: {
          "Content-Type": "application/json"
        },
        queryStringParameters: queryParams
      })

      if (success) {
        config.resultados = data
        return data
      }

      return []
    }


  }
})()

export default ENIFControls