import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Grid } from "@material-ui/core"
import { navigate } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import * as React from "react"

import { SectionContainer, SectionText, SectionTitle } from ".."
import theme from "../../../App.theme"
import { Button } from "../../_common"

import rawOptions from "./rawOptions"

import useStyles from "./styles"

interface IIntro {
  title: string
  content: string
  sectionID: string
  cta?: string
  route?: string
  image?: FluidObject
  alt?: string
  reversed?: boolean
  imageBG?: string
  imgRoute?: string
}

const TextImageSection: React.FC<IIntro> = ({
                                              image,
                                              reversed,
                                              title,

                                              route,
                                              cta,
                                              alt,
                                              sectionID,

                                              imageBG = theme.palette.extras.olive,

                                              content,
                                              imgRoute
                                            }) => {
  const styles = useStyles({ image, imageBG, imgRoute })
  let contenidoBodyJSON = null

  try {
    contenidoBodyJSON = JSON.parse(content)
  } catch {
  }

  return (
    <SectionContainer { ...{ sectionID } }>
      <Grid container direction={ reversed ? "row-reverse" : "row" }>
        <Grid item className={ styles.leftPanel } md={ image ? 6 : 12 }>
          <Grid item container>
            <SectionTitle { ...{ title } } />
            { contenidoBodyJSON ? (
              <article className={ styles.article }>
                { documentToReactComponents(contenidoBodyJSON, rawOptions) }
              </article>
            ) : (
              <SectionText text={ content } />
            ) }
          </Grid>

          { cta && route && (
            <Grid item container className={ styles.btnContainer }>
              <Button aria={ cta } goTo={ route }>
                { cta }
              </Button>
            </Grid>
          ) }
        </Grid>

        { image && (
          <Grid item container className={ styles.imgRoot } md={ 6 } onClick={() => navigate(imgRoute)}>
            <Grid item container className={ styles.imgContainer }>
              <Img className={ styles.img } fluid={ image } { ...{ alt } } />
            </Grid>
          </Grid>
        ) }
      </Grid>
    </SectionContainer>
  )
}

export default TextImageSection
