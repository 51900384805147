import { FC } from 'react'
import * as React from "react"
import Img, { FluidObject } from 'gatsby-image'

import useStyles from './styles'
import { useLoaded } from '../../../hooks'

type ChildImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

interface ILogo {
  width?: number
  image: ChildImage
  alt: string
}

const Logo: FC<ILogo> = ({ width, image, alt }) => {
  const { img } = useStyles({ width })

  const loaded = useLoaded()

  return (
    loaded && (
      <Img className={img} fluid={image.childImageSharp.fluid} {...{ alt }} />
    )
  )
}

export default Logo
