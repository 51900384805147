import { makeStyles } from "@material-ui/core"
import { FluidObject } from "gatsby-image"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  imgRoot: {
    alignItems: "center",
    justifyContent: "center",
  },

  imgContainer: {
    padding: theme.spacing(0.25),
    borderRadius: theme.roundness,

    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },

    [theme.breakpoints.up("md")]: {
      width: "60%"
    }
  },

  img: {
    flexGrow: 1,
    borderRadius: theme.roundness
  },
}))
