/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVariable = /* GraphQL */ `
  mutation CreateVariable(
    $input: CreateVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    createVariable(input: $input, condition: $condition) {
      variable
      descripcion
      categoria
      sector
      tipo
      referencia
      createdAt
      updatedAt
    }
  }
`;
export const updateVariable = /* GraphQL */ `
  mutation UpdateVariable(
    $input: UpdateVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    updateVariable(input: $input, condition: $condition) {
      variable
      descripcion
      categoria
      sector
      tipo
      referencia
      createdAt
      updatedAt
    }
  }
`;
export const deleteVariable = /* GraphQL */ `
  mutation DeleteVariable(
    $input: DeleteVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    deleteVariable(input: $input, condition: $condition) {
      variable
      descripcion
      categoria
      sector
      tipo
      referencia
      createdAt
      updatedAt
    }
  }
`;
export const batchAddVariables = /* GraphQL */ `
  mutation BatchAddVariables($variables: [CreateVariableInput]) {
    batchAddVariables(variables: $variables) {
      variable
      descripcion
      categoria
      sector
      tipo
      referencia
      createdAt
      updatedAt
    }
  }
`;
export const createVariableEnif = /* GraphQL */ `
  mutation CreateVariableEnif(
    $input: CreateVariableEnifInput!
    $condition: ModelVariableEnifConditionInput
  ) {
    createVariableEnif(input: $input, condition: $condition) {
      cve_pregunta
      seccion
      pregunta
      createdAt
      updatedAt
    }
  }
`;
export const updateVariableEnif = /* GraphQL */ `
  mutation UpdateVariableEnif(
    $input: UpdateVariableEnifInput!
    $condition: ModelVariableEnifConditionInput
  ) {
    updateVariableEnif(input: $input, condition: $condition) {
      cve_pregunta
      seccion
      pregunta
      createdAt
      updatedAt
    }
  }
`;
export const deleteVariableEnif = /* GraphQL */ `
  mutation DeleteVariableEnif(
    $input: DeleteVariableEnifInput!
    $condition: ModelVariableEnifConditionInput
  ) {
    deleteVariableEnif(input: $input, condition: $condition) {
      cve_pregunta
      seccion
      pregunta
      createdAt
      updatedAt
    }
  }
`;
export const createVariableCategoriaEnif = /* GraphQL */ `
  mutation CreateVariableCategoriaEnif(
    $input: CreateVariableCategoriaEnifInput!
    $condition: ModelVariableCategoriaEnifConditionInput
  ) {
    createVariableCategoriaEnif(input: $input, condition: $condition) {
      cve_cat
      categoria
      createdAt
      updatedAt
    }
  }
`;
export const updateVariableCategoriaEnif = /* GraphQL */ `
  mutation UpdateVariableCategoriaEnif(
    $input: UpdateVariableCategoriaEnifInput!
    $condition: ModelVariableCategoriaEnifConditionInput
  ) {
    updateVariableCategoriaEnif(input: $input, condition: $condition) {
      cve_cat
      categoria
      createdAt
      updatedAt
    }
  }
`;
export const deleteVariableCategoriaEnif = /* GraphQL */ `
  mutation DeleteVariableCategoriaEnif(
    $input: DeleteVariableCategoriaEnifInput!
    $condition: ModelVariableCategoriaEnifConditionInput
  ) {
    deleteVariableCategoriaEnif(input: $input, condition: $condition) {
      cve_cat
      categoria
      createdAt
      updatedAt
    }
  }
`;
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      email
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      email
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      email
      name
      createdAt
      updatedAt
    }
  }
`;
