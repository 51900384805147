import { Box, Typography } from "@material-ui/core"
import * as React from "react"

import useStyles from "./styles"

interface ISectionTitle {
  title: string
  centered?: boolean
  dark?: boolean
}

const SectionTitle: React.FC<ISectionTitle> = ({ title, centered, dark }) => {
  const { root, title: titleClass } = useStyles({ centered, dark })
  return (
    <Box className={ root }>
      <Typography className={ titleClass } component={"h1"}>{ title }</Typography>
    </Box>
  )
}

export default SectionTitle
