import { withStyles } from "@material-ui/core/styles"
import { InputBase } from "@material-ui/core"
import theme from "../../../App.theme"

const Input = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    height: 32,
    width: "100%",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paperLight,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    padding: theme.spacing(0.5, 1),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
    },
  },
}))(InputBase)

export default Input
