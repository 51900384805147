import { StateElems } from "."
import { ReducerAction } from "../contextHelpers"

export type ReducerType = "set_indicadores" | "set_loading" | "add_error"

export default (
  state: StateElems,
  action: ReducerAction<ReducerType, StateElems>
) => {
  const { type, payload } = action

  switch (type) {
    // CRUD
    case "set_indicadores":
      return {
        ...state,
        indicadores: payload.indicadores,
      }

    // HELPERS
    case "set_loading":
      return { ...state, loadingIndicadores: payload.loadingIndicadores }
    case "add_error":
      return { ...state, errorMessage: payload.errorMessage }

    // DEFAULT
    default:
      return state
  }
}
