import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Grid } from "@material-ui/core"
import * as React from "react"
import { SectionText } from "../../_layout"

import rawOptions from "./rawOptions"

import useStyles from "./styles"

interface IRichTextRenderer {
  content: string
  big?: boolean
  searchText?: string
  withSearch?: boolean
}

const RichTextRenderer: React.FC<IRichTextRenderer> = ({ content, big, withSearch, searchText = "" }) => {
  const styles = useStyles({ big })
  let parsedContent = null
  let reactRichTextComponents = null


  try {
    parsedContent = JSON.parse(content)

    if (withSearch) {
      const filteredBySearch = {
        ...parsedContent,
        content: parsedContent.content.filter(element => element.content.some(content => content?.value?.toLowerCase().includes(searchText)))
      }
      reactRichTextComponents = documentToReactComponents(filteredBySearch, rawOptions)
    } else {
      reactRichTextComponents = documentToReactComponents(parsedContent, rawOptions)
    }

  } catch (err) {
    console.log("Search error: ", err)
  }


  return (
    <Grid item container>
      { reactRichTextComponents ? (
        <article className={ styles.article }>
          { reactRichTextComponents }
        </article>
      ) : (
        <SectionText text={ content } />
      ) }
    </Grid>
  )
}

export default RichTextRenderer
