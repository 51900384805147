import { Grid } from "@material-ui/core/"
import { useEffect } from "react"
import * as React from "react"
import theme from "../../../App.theme"
import { FormSchemaTextObject, TAnyCallback } from "../../../helpers/Types"
import { useFormUpdate } from "../../../hooks"
import { FormTextInput } from "../../_forms"
import { IFormStateOptions } from "../../_forms/FormContainer/FormContainer"


import useSchemas from "./form.schema"

export interface SearchFormSchemaData {
  text: FormSchemaTextObject
}

interface SearchFormProps {
  onFormSubmit: TAnyCallback
  stateOptions?: IFormStateOptions
}

const SearchForm = React.forwardRef(({ onFormSubmit }: SearchFormProps, ref: any) => {
  const { initialState, validationStateSchema } = useSchemas()

  const {
    updatedData,
    handleUpdateData
  } = useFormUpdate<SearchFormSchemaData>(
    initialState,
    validationStateSchema,
    async () => {
    }
  )

  useEffect(() => {
    onFormSubmit(updatedData.text.value)
  }, [updatedData])

  return (
    <Grid container>
      <Grid item md={8} style={ { width: "100%" } }>
        <FormTextInput
          { ...{ ref } }
          required
          name="text"
          label=""
          value={ updatedData.text.value }
          errorValue={ null }
          onChange={ handleUpdateData }
          placeholder={ "Busca dentro del glosario" }
        />
      </Grid>

      <Grid item md={4} />
    </Grid>
  )
})

export default SearchForm
