import { FC, ReactNode, useEffect, useState } from "react"
import * as React from "react"
import { Grid } from "@material-ui/core"
import RubberBand from "react-reveal/RubberBand"

import useStyles from "./styles"

interface IRubberBandWrapper {
  children: ReactNode
  floating?: boolean
}

const DURATION: number = 1000

const RubberBandWrapper: FC<IRubberBandWrapper> = ({ children, floating }) => {
  const [buttonHovered, setButtonHovered] = useState<boolean>(false)
  const styles = useStyles()

  useEffect(() => {
    if (buttonHovered) {
      setTimeout(() => setButtonHovered(false), DURATION)
    }
  }, [buttonHovered])

  return (
    <Grid
      className={`${styles.buttonContainer} ${floating ? styles.floating : ""}`}
      onClick={() => setButtonHovered(true)}
    >
      <RubberBand duration={DURATION} when={buttonHovered}>
        {children}
      </RubberBand>
    </Grid>
  )
}

export default RubberBandWrapper
