export default (
  pagina: number,
  numPag: number,
  paginationLength: number = 5
) => {
  if (numPag <= paginationLength) {
    const arr: (string | number)[] = Array.from(
      Array(numPag),
      (_, index) => index + 1
    )
    return arr
  }

  if (pagina === 1) {
    const arr: (string | number)[] = Array.from(
      Array(paginationLength),
      (_, index) => index + pagina
    )
    arr.push("next", numPag)
    return arr
  }
  if (pagina === 2) {
    const arr: (string | number)[] = Array.from(
      Array(paginationLength - 1),
      (_, index) => index + pagina
    )
    arr.unshift("prev", 1)
    arr.push("next", numPag)
    return arr
  }

  if (pagina === 3) {
    const arr: (string | number)[] = Array.from(
      Array(paginationLength - 2),
      (_, index) => index + pagina
    )
    arr.unshift("prev", 1, 2)
    arr.push("next", numPag)
    return arr
  }

  if (pagina >= numPag - 2) {
    const arr: (string | number)[] = Array.from(Array(1)).fill(numPag)
    arr.unshift(1, "prev", numPag - 4, numPag - 3, numPag - 2, numPag - 1)
    return arr
  }

  const arr: (string | number)[] = Array.from(Array(1)).fill(pagina)
  arr.unshift(1, "prev", pagina - 2, pagina - 1)
  arr.push(pagina + 1, pagina + 2, "next", numPag)
  return arr
}
