import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  centeredForm?: boolean
  wideForm?: boolean
}

const MAX_WIDTH: number = 300

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  formWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: ({ centeredForm }: IStyleProps) => centeredForm && "center",
  },

  formContainer: {
    flexDirection: "column",
    maxWidth: ({ wideForm }: IStyleProps) => !wideForm && MAX_WIDTH,
    margin: theme.spacing(1, 0),
  },

  submitWrapper: {
    maxWidth: ({ wideForm }: IStyleProps) => !wideForm && MAX_WIDTH,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: theme.spacing(3),
  },
}))
