import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
    query {
        site {
            siteMetadata {
                siteTitle: title
                siteDesc: description
                author
                siteUrl
                cardImg: image
                twitterUsername
            }
        }
    }
`

interface ISEOProps {
  title: string
  description: string
}

const SEO: React.FC<ISEOProps> = ({ title, description }) => {
  const { site } = useStaticQuery(getData)
  const {
    siteDesc,
    siteTitle,
    siteUrl,
    cardImg,
    twitterUsername
  } = site.siteMetadata

  return (
    <Helmet htmlAttributes={ { lang: "es" } } title={ `${ title } | ${ siteTitle }` }>
      <meta name="description" content={ description || siteDesc } />
      <meta name="image" content={ cardImg } />
      {/* Facebook Cards */ }
      <meta property="og:url" content={ siteUrl } />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ siteTitle } />
      <meta property="og:description" content={ siteDesc } />
      <meta property="og:image" content={ `${ siteUrl }${ cardImg }` } />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      {/* Twitter Cards */ }
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={ twitterUsername } />
      <meta name="twitter:title" content={ siteTitle } />
      <meta name="twitter:description" content={ siteDesc } />
      <meta name="twitter:image" content={ `${ siteUrl }${ cardImg }` } />
    </Helmet>
  )
}

export default SEO
