import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  socialWrapper: {
    flexDirection: "column",
  },

  socialTitle: {
    marginBottom: theme.spacing(4),
    "& span": {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  socialButtons: {
    justifyContent: "center",
    alignItems: "center",
  },

  socialButton: {
    margin: theme.spacing(1),

    "& span": {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.secondary.main,
      transition: theme.palette.extras.transitions.fastEaseInOut,

      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h4.fontSize,
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}))
