import { Box, Tab, Tabs, Tooltip, Typography, Zoom } from "@material-ui/core"
import * as React from "react"
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { Icon } from "../../../helpers/Types"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import useStyles from "./styles"


interface ITabPanelProps {
  value: number
  index: number
  handleGlobalHeight: (heightFromComponent: any) => void
  globalHeight: number
  dark?: boolean
}

const a11yProps = index => ({
  id: `scrollable-force-tab-${ index }`,
  "aria-controls": `scrollable-force-tabpanel-${ index }`
})

const TabPanel: FC<ITabPanelProps> = props => {
  const {
    children,
    value,
    index,
    handleGlobalHeight,
    globalHeight,
    dark,
    ...other
  } = props

  const ref = useRef<HTMLDivElement>()

  const refHeight = ref.current && ref.current.getBoundingClientRect().height

  useEffect(() => {
    if (refHeight > globalHeight) {
      handleGlobalHeight(refHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refHeight])

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `scrollable-force-tabpanel-${ index }` }
      aria-labelledby={ `scrollable-force-tab-${ index }` }
      { ...other }
    >
      <div
        style={ {
          padding: dark ? 0 : 32,
          overflow: "hidden",
          height: "100%"
          // minHeight: globalHeight !== 0 && globalHeight,
        } }
        ref={ ref }
      >
        { children }
      </div>
    </Typography>
  )
}

interface ITab {
  title: string
  component: ReactNode
  icon: Icon
  renders: boolean
  tooltip?: string
}

interface ITabsComponent {
  tabs: ITab[]
  value: number
  setValue: Dispatch<SetStateAction<number>>
  dark?: boolean
  tabsText?: string
}

const TabsComponent: FC<ITabsComponent> = ({ tabs, value, setValue,tabsText, dark }) => {
  const classes = useStyles({ dark })
  const [globalHeight, setGlobalHeight] = useState(0)

  const breakpoints = useBreakpoint()

  const handleChange = (_, newValue: number) => {
    setValue(newValue)
  }
  const filteredTabs = tabs.filter(({ renders }) => renders)

  const handleGlobalHeight = useCallback(heightFromComponent => {
    setGlobalHeight(heightFromComponent)
  }, [])


  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.tabsRootWrapper}>

        <Typography className={classes.tabsText}>{tabsText}</Typography>
        <Tabs
          classes={ { root: classes.tabWrapper } }
          value={ value }
          onChange={ handleChange }
          variant="scrollable"
          scrollButtons="on"
          aria-label="pestañas"
        >
          { filteredTabs.map(({ title, icon, tooltip }, i) => (
            <Tooltip
              key={ i }
              TransitionComponent={ Zoom }
              title={ tooltip || title }
            >
              <Tab
                fullWidth
                key={ i }
                label={ title }
                icon={ icon }
                { ...a11yProps(i) }
                classes={ { root: classes.tab } }
                className={
                  value === i ? classes.selectedTab : classes.unselectedTab
                }
              />
            </Tooltip>
          )) }
        </Tabs>
      </Box>
      { filteredTabs.map(({ component }, i) => (

        <TabPanel
          key={ i }
          value={ value }
          index={ i }
          handleGlobalHeight={ handleGlobalHeight }
          globalHeight={ globalHeight }
          { ...{ dark } }
        >
          { value === i && component }
        </TabPanel>

      )) }
    </>
  )
}

export default TabsComponent
